package com.picme.sdk2

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.ImageRaw
import com.lightningkite.kiteui.models.ImageSource
import kotlinx.datetime.Instant
import kotlinx.datetime.serializers.InstantIso8601Serializer
import kotlinx.serialization.*
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.modules.serializersModuleOf
import kotlin.jvm.JvmInline

typealias Int16 = Short
typealias Int64 = Long
typealias UInt16 = Long
typealias Int32 = Int
typealias Uri = String
typealias DateTime = @Serializable(InstantIso8601Serializer2::class) Instant
typealias RedirectResponse = ImageRaw

val json = Json {
    encodeDefaults = false
    ignoreUnknownKeys = true
    serializersModule = serializersModuleOf(InstantIso8601Serializer2)
}

public object InstantIso8601Serializer2 : KSerializer<Instant> {

    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("Instant", PrimitiveKind.STRING)

    override fun deserialize(decoder: Decoder): Instant =
        Instant.parse(decoder.decodeString().let {
            if (it.endsWith("z", true)) it else it + "Z"
        })

    override fun serialize(encoder: Encoder, value: Instant) {
        encoder.encodeString(value.toString())
    }

}

@Serializable
@JvmInline
value class Retainable<out T>(val raw: T) {
    val retained: Boolean get() = raw == RetainExistingValue

    companion object {
        @Suppress("UNCHECKED_CAST")
        val retain: Retainable<Nothing> = Retainable<Any?>(RetainExistingValue) as Retainable<Nothing>
    }

    override fun toString(): String = when (raw) {
        is RetainExistingValue -> "[retain]"
        else -> "$raw"
    }
}

fun <T> Retainable<T>.getOrDefault(default: T) = if (retained) default else raw
fun <T, R> Retainable<T>.ifPresent(action: (T) -> R): R? = if (retained) null else action(raw)

object RetainExistingValue

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline accessToken: (suspend () -> String?) = { null },
    parameters: Map<String, String>,
    body: RequestBody? = null,
): OUT = com.lightningkite.kiteui.fetch(
    url = url + "?" + parameters.entries.joinToString("&") { it.key + "=" + it.value },
    method = method,
    headers = httpHeaders().apply {
        accessToken()?.let { append("Authorization", "Bearer ${it}") }
    },
    body = body,
).let {
    if (!it.ok) throw Exception(it.text())
    if (OUT::class == RedirectResponse::class) it.blob().let(::RedirectResponse) as OUT
    else if (OUT::class == Unit::class) Unit as OUT
    else json.decodeFromString(it.text())
}

suspend inline fun <reified IN, reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline accessToken: (suspend () -> String?) = { null },
    parameters: Map<String, String>,
    body: IN
): OUT = com.lightningkite.kiteui.fetch(
    url = url + "?" + parameters.entries.joinToString("&") { it.key + "=" + it.value },
    method = method,
    headers = httpHeaders().apply {
        accessToken()?.let { append("Authorization", "Bearer ${it}") }
    },
    body = RequestBodyText(json.encodeToString(body), "application/json"),
).let {
    if (it.ok && OUT::class == Unit::class) Unit as OUT
    else if (it.ok) json.decodeFromString(it.text())
    else {
        throw Exception(it.text())
    }
}

inline fun <reified T> T.toParamString(): String {
    val js = json.encodeToJsonElement(this)

    return (
            if (js is JsonPrimitive) js.content
            else js.toString()
            ).let(::encodeURIComponent)
}


