package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.views.*

fun ViewWriter.userInfoPopover() =
    centered - sizeConstraints(width = 47.dp, height = 47.dp) - unpadded - menuButton {
        requireClick = true

        val profileImg = shared { session()?.authentication?.profileImage() }

        val firstLetterOfName = Property("")

        reactiveScope {
            firstLetterOfName set (session.await()?.authentication?.getMyUserInfo()?.userData?.name?.ifBlank { createUserName() }
                ?: createUserName()).take(1)
                .replaceFirstChar { it.titlecase() }
        }

        dynamicTheme {
            if (profileImg() == null) {
                ThemeDerivation {
                    appMaterialTheme[ImportantSemantic].theme.copy(
                        cornerRadii = CornerRadii.ForceConstant(100.rem)
                    ).withBack
                }
            } else {
                ThemeDerivation {
                    it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack
                }
            }
        }
        centered - h3 {
            ::exists { firstLetterOfName().isNotEmpty() && profileImg() == null }
            ::content { firstLetterOfName() }
        }
        centered - icon {
            ::exists { firstLetterOfName().isEmpty() && profileImg() == null }
            source = PIcon.account
        }
        image {
            ::exists { session()?.authentication?.profileImage() != null }
            scaleType = ImageScaleType.Crop
            refreshOnParamChange = true
            ::source { session()?.authentication?.profileImage() }
        }

        preferredDirection = PopoverPreferredDirection.belowLeft
        opensMenu {
            popoverWrapper(width = 20.rem) {
                col {
                    spacing = 0.25.rem
                    col {
                        centered - unpadded - stack {
                            ::exists { profileImg() == null }
                            dynamicTheme {
                                ThemeDerivation {
                                    appMaterialTheme[ImportantSemantic].theme.copy(
                                        cornerRadii = CornerRadii.ForceConstant(100.rem)
                                    ).withBack
                                }
                            }
                            centered - sizeConstraints(width = 4.rem, height = 4.rem) - stack {
                                centered - icon {
                                    ::exists { firstLetterOfName().isEmpty() }
                                    source = PIcon.account
                                }
                            }
                            centered - bold - textSize(2.5.rem) - text {
                                ::exists { firstLetterOfName().isNotEmpty() }
                                ::content { firstLetterOfName() }
                            }
                        }
                        centered - unpadded - stack {
                            ::exists { profileImg() != null }
                            dynamicTheme {
                                ThemeDerivation {
                                    it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack
                                }
                            }
                            centered - sizeConstraints(width = 4.rem, height = 4.rem) - image {
                                scaleType = ImageScaleType.Crop
                                ::source { profileImg() }
                            }
                        }
                    }

                    val name = shared { session()?.authentication?.getMyUserInfo()?.userData?.name ?: "" }
                    h4 {
                        ::exists { name().isNotBlank() }
                        ::content{ name() }
                        align = Align.Center
                    }

                    separatorTheme - separator()

                    ifElse(
                        condition = { session()?.isVerifiedAccount() == true },
                        ifTrue = {
                            col {
                                popoverItem {
                                    icon.source = PIcon.account
                                    text.content = "Account"
                                    button.onClick {
                                        closePopovers()
                                        dialogScreenNavigator.navigate(ProfileScreen)
                                    }
                                }
                                popoverItem {
                                    icon.source = PIcon.security
                                    text.content = "Password & Security"
                                    button.onClick {
                                        closePopovers()
                                        dialogScreenNavigator.navigate(PasswordAndSecurity)
                                    }
                                }
                                popoverItem {
                                    icon.source = PIcon.subscription
                                    text.content = "Subscription"
                                    button.onClick {
                                        closePopovers()
                                        dialogScreenNavigator.navigate(Plan)
                                    }
                                }
                                popoverItem {
                                    icon.source = PIcon.qrcode
                                    text.content = "Refer a Friend"
                                    button.onClick {
                                        closePopovers()
                                        dialogScreenNavigator.navigate(ReferAFriend)
                                    }
                                }
                                popoverItem {
                                    icon.source = PIcon.logout
                                    text.content = "Logout"
                                    button.onClick {
                                        closePopovers()
                                        logout()
                                    }
                                }
                                ThemeDerivation {
                                    it.copy(
                                        background = Color.orange.applyAlpha(0.5f),
                                        outline = Color.orange,
                                        outlineWidth = 2.dp
                                    ).withBack
                                }.onNext - col {
                                    ::exists { devSettings() }
                                    bold - text { ::content { "Backend: ${selectedBackend()}" } }
                                    subtext("This will only appear when Developer settings are enabled")
                                }
                            }
                        },
                        ifFalse = {
                            centered - col {
                                space {}
                                loginOrSignupButton { closePopovers() }
                            }
                        }
                    )
                }
            }
        }
    }


