package com.picme

//@JsNonModule @JsModule("lz-string") external fun compressToUTF16(string: String): String
//@JsNonModule @JsModule("lz-string") external fun decompressFromUTF16(string: String): String

@JsNonModule @JsModule("lz-string") external val LZString: LZStringInterface
external interface LZStringInterface{
    fun compressToUTF16(string: String): String
    fun decompressFromUTF16(string: String): String
}

actual fun compressForLocalStorage(string: String): String = LZString.compressToUTF16(string)
actual fun uncompressForLocalStorage(string: String): String = LZString.decompressFromUTF16(string)