package com.picme.sdk2.caching

import com.picme.InviteType
import com.picme.clientInfo
import com.picme.sdk2.generated.*
import com.picme.sdk2.generated.collection2.GetInviteCodeResponse
import com.picme.sdk2.generated.collection2.ListInviteCodesResponse
import com.picme.sdk2.generated.collection2.PatchInviteCodeBody

object InviteCache {
    val codes: MutableMap<InviteCodeId, InviteCode> = mutableMapOf()
    val collectionIdToInvite: MutableMap<RecordGlobalId, List<InviteCodeId>> = mutableMapOf()


    fun setInvite(code: InviteCode) {
        codes[code.inviteCodeId] = code
    }

    fun setColl(key: RecordGlobalId?, codes: List<InviteCode>) {
        if (key == null) return
        collectionIdToInvite[key] = codes.map { it.inviteCodeId }
        codes.forEach { setInvite(it) }
    }

    fun invalidateAll() {
        collectionIdToInvite.clear()
        codes.clear()
        cachedReferral = null
    }

    fun getInvite(id: InviteCodeId): GetInviteCodeResponse? {
        return codes[id]?.let(::GetInviteCodeResponse)
    }

    fun listInvites(linkPrimaryGlobalId: RecordGlobalId?): ListInviteCodesResponse? {
        val cacheKey = linkPrimaryGlobalId ?: return null

        val cachedOrFetch = collectionIdToInvite[cacheKey]?.let {
            it.map { getInvite(it)?.inviteCode }
        }
        if (cachedOrFetch?.any { it == null } == true) {
            return null
        }

        return cachedOrFetch?.filter { it!!.clientInfo() != null }
            ?.map {
                // Was naming them Request Uploads, but should've been named Request Photos
                if (it!!.name == "Request Uploads") it.copy(name = "Request Photos")
                else it
            }?.distinctBy { it.clientInformation }?.let(::ListInviteCodesResponse)
    }

    fun patchInviteCode(inviteId: InviteCodeId, body: PatchInviteCodeBody) {
        codes[inviteId]?.let {
            setInvite(
                it.copy(
                    temporarilyDisabled = body.temporarilyDisabled ?: it.temporarilyDisabled,
                    start = body.start,
                    end = body.end
                )
            )
        }
    }

    var cachedReferral: InviteCode? = null

    fun getReferralCode(): InviteCode? {
        return cachedReferral
    }
    fun setReferralCode(referral: InviteCode) {
        cachedReferral = referral
    }
}