package com.picme.sdk2

import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.HttpMethod
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.PersistentProperty
import com.picme.sdk2.generated.ApiEndpoints
import com.picme.sdk2.generated.ad.AdHandlerEndpoints
import com.picme.sdk2.generated.authentication.AuthenticationHandlerEndpoints
import com.picme.sdk2.generated.collection2.CollectionHandler2Endpoints
import com.picme.views.Environment
import com.picme.views.defaultBackendUrl
import com.picme.views.selectedBackend
import kotlinx.serialization.Serializable


val api = PersistentProperty<ApiEndpoints?>("picme-server-url", null)

@Serializable
data class BackendUrls(
    val adHandler: String,
    val authenticationHandler: String,
    val collectionHandler2: String,
//    val qrGenerationHandler: String,
)

suspend fun fetchAndSetBackendUrls(baseUrl: String, defaultBackendUrl: Environment): ApiEndpoints {
    val urls = try {
        com.lightningkite.kiteui.fetch("${baseUrl}/endpoints.json", HttpMethod.GET).let {
            val decoded = json.decodeFromString<BackendUrls>(it.text())
            BackendUrls(
                adHandler = "${baseUrl}/${decoded.adHandler}",
                authenticationHandler = "${baseUrl}/${decoded.authenticationHandler}",
                collectionHandler2 = "${baseUrl}/${decoded.collectionHandler2}"
            )
        }
    } catch (e: Exception) {
        when (defaultBackendUrl) {
            Environment.Dev ->
                BackendUrls(
                    adHandler = "https://hbcpo2lu26lboi77dfpofk2una0oqssb.lambda-url.us-west-2.on.aws/",
                    authenticationHandler = "https://cxs4j7khkuzsbypy7efmlusy4i0afyyh.lambda-url.us-west-2.on.aws/",
                    collectionHandler2 = "https://askgs7ib2fgvdm74jogfkvatty0evfov.lambda-url.us-west-2.on.aws/"
                )

            Environment.Staging -> BackendUrls(
                adHandler = "https://723w7kodgwclp3fpd6jwsactvm0iqlpd.lambda-url.us-west-2.on.aws/",
                authenticationHandler = "https://cwctfwiarortm2p4gaue425bzq0dobay.lambda-url.us-west-2.on.aws/",
                collectionHandler2 = "https://b2ymkqwv6fac3n5turhv35ro5q0ubukl.lambda-url.us-west-2.on.aws/"
            )

            Environment.Prod -> BackendUrls(
                adHandler = "https://zvdqn223wfrxcbl2dixqjgx6ti0khnps.lambda-url.us-east-1.on.aws/",
                authenticationHandler = "https://5ivdywg6des2gg7yfc37nsqnwy0wioim.lambda-url.us-east-1.on.aws/",
                collectionHandler2 = "https://byvpnlgqzkwlx3dhuogct3e7v40cngoa.lambda-url.us-east-1.on.aws/"
            )
        }
    }
    return ApiEndpoints(
        adHandler = AdHandlerEndpoints(urls.adHandler),
        authenticationHandler = AuthenticationHandlerEndpoints(urls.authenticationHandler),
        collectionHandler2 = CollectionHandler2Endpoints(urls.collectionHandler2),
    ).also {
        api set it
    }
}

val unauthApi: Readable<ApiEndpoints> = shared {
    api.await() ?: fetchAndSetBackendUrls(selectedBackend(), defaultBackendUrl)
}

@Serializable
data class UploadImageInfo(
    val uploadId: String,
    val uploadUrl: String,
    val metadataUploadUrl: String,
    val anonymousOwnershipToken: String
)

expect class BackgroundUploadManager() {
    fun startBackgroundUploadRequest(uploadInfo: UploadImageInfo, file: FileReference)
}

val uploadManager = BackgroundUploadManager()
val backgroundUploads = Property(listOf<BackgroundUploadStatus>())

data class BackgroundUploadStatus(
    val uploadInfo: UploadImageInfo? = null,
    val progress: Float = 0f,
    val state: State = State.InProgress
) {
    enum class State { InProgress, Complete, Failed }
}
