package com.picme.components

import com.lightningkite.kiteui.delay
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.report
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.UploadId


fun ViewWriter.openCopyMenu(images: List<UploadId>) {
    dialogGeneric { close ->
        formCol {
            centered - h4("Confirm Copy")
            text("Copy the selected items to")

            val destinationColl = Property<PCollection?>(null)

            atStart - sizeConstraints(minWidth = 16.rem) - card - menuButton {
                row {
                    expanding - centered - text { ::content { destinationColl()?.name ?: "Select a collection" } }
                    atEnd - icon { source = Icon.dropdown }
                }
                requireClick = true
                opensMenu {
                    sizeConstraints(width = 14.rem, height = 14.rem) - expanding - recyclerView {
                        children(shared {
                            session.awaitNotNull().collection2.listCollectionsLive().all().filter {
                                if (it.collection.collectionId == currentCollection()?.collectionId) false
                                else ownsCollection(it)
                            }
                        }) { option ->
                            popoverItemTheme - button {
                                dynamicTheme {
                                    if (destinationColl() == option().collection) ThemeDerivation {
                                        it.copy(background = Color.menuIconBg).withBack
                                    }
                                    else null
                                }
                                atStart - text {
                                    ::content { option().collection.name }
                                }
                                onClick {
                                    destinationColl set option().collection
                                    closePopovers()
                                }
                            }
                        }
                    }
                }
            }

            space()

            actionOrCancelSection(
                onCancel = { close() },
                actionButton = {
                    importantButton("Copy", enabled = shared { destinationColl() != null }) {
                        val dest = destinationColl() ?: return@importantButton
                        val sourceColl = currentCollection() ?: return@importantButton
                        val sess = session() ?: return@importantButton
                        openProgressModal("Copying ${images.size} ${"item".pluralize(images.size)}",
                            execute = {
                                itemCount = images.size

                                for ((index, uploadId) in images.withIndex()) {
                                    val details = sess.collection2.getUpload(
                                        collectionId = sourceColl.collectionId,
                                        uploadId = uploadId
                                    )

                                    currentIndex = index
                                    image = ImageRemote(details.getThumbnailUri)
                                    try {
                                        individualItemProgress = 0.2f
                                        session.awaitNotNull().collection2.copyUpload(
                                            caption = details.upload.caption.raw,
                                            sourceCollectionId = currentCollection()?.collectionId!!,
                                            sourceUploadId = uploadId,
                                            destinationCollectionId = dest.collectionId,
                                            anonymous = false,
                                            allowDuplicates = false,
                                        )
                                        individualItemProgress = 0.8f
                                        delay(1000)
                                        individualItemProgress = 1f
                                        delay(1000)
                                    } catch (e: Exception) {
                                        e.report()
                                    }
                                }
                            },
                            onComplete = {
                                showToast("Successfully copied ${images.size} ${"item".pluralize(images.size)}")
                            }
                        )

                        close()
                        CollectionState.selectItems.stopSelecting()
                    }
                }
            )
        }
    }
}

