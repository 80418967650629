package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.authScreen
import com.picme.components.importantButton
import com.picme.session


@Routable("create-account-finish")
object FinishCreateAccount : Screen, NoTopBar {
    override fun ViewWriter.render() {
        authScreen("What's your name?") {

            formCol {
                fadedForeground.onNext - text {
                    content = "Enter your name or generate a random one to remain anonymous."
                    align = Align.Center
                }

                space()
                val tf: TextField
                fieldSizedTheme - row {
                    spacing = 0.dp
                    padded - expanding - centered - textField {
                        content bind createUserName
                        hint = "Enter name"
                        tf = this
                    }

                    separator { }

                    space()
                    centered - ThemeDerivation {
                        it.copy(
                            outline = Color.white,
                            cornerRadii = CornerRadii.RatioOfSize(2f)
                        ).withoutBack
                    }.onNext - button {
                        spacing = 0.5.rem
                        icon {
                            source = PIcon.refresh
                        }
                        onClick {
                            createUserName set "Anonymous ${randomAnimals.random()}"
                        }
                    }
                    space()
                }

                importantButton("Continue", field = tf, enabled = shared {
                    createUserName() != ""
                }) {
                    session.awaitNotNull().authentication.setUserAttributes(name = createUserName(), setTosRead = true)
                    if (acceptingInvite() == null) {
                        navigateToCollOrLanding()
                    } else {
                        acceptQrCode()
                    }
                }
            }
        }
    }
}


