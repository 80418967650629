package com.picme.views.share

import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.picme.components.PicmeDialog
import com.picme.components.SelectedTab
import com.picme.components.dialogTopBar
import com.picme.components.tabSelect
import com.picme.sdk2.generated.CollectionId

enum class ShareSubView { Invites, InviteDetail, Guests }
class ShareView(val collectionId: CollectionId, val onBack: suspend () -> Unit) : PicmeDialog() {
    val tab = Property(SelectedTab.First)
    val invite = Property<ViewInviteInfo?>(null)

    override fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = shared {
            invite()?.inviteCode?.name ?: "Invites & Guests"
        },
        onBack = {
            if (invite() != null) {
                invite set null
            } else {
                onBack()
            }
        },
    )

    override val withinCollContext = true
    override fun ViewWriter.content() {


        tab bind tab.withWrite { invite set null }

        expanding - padded - col {
            stack {
                ::exists { invite() == null }
                tabSelect(tab, "Invites", "Guests")
            }
            expanding - swapView {
                swapping(
                    current = {
                        if (invite() != null) ShareSubView.InviteDetail
                        else if (tab() == SelectedTab.First) ShareSubView.Invites
                        else ShareSubView.Guests
                    },
                    views = { currTab ->
                        when (currTab) {
                            ShareSubView.Invites -> with(Invites(collectionId) { invite set it }) { render() }
                            ShareSubView.InviteDetail -> with(QRCodeDetail(
                                qrCodeInfo = shared { invite.awaitNotNull() },
                                onBack = { invite set null }
                            )) { render() }

                            ShareSubView.Guests -> with(Guests(collectionId)) { render() }
                        }
                    }
                )
            }
        }
    }

}