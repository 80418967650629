package com.picme.components

import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.awaitNotNull
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.compact
import com.lightningkite.kiteui.views.danger
import com.lightningkite.kiteui.views.direct.*
import com.picme.formCol
import com.picme.sdk2.generated.collection2.PatchInviteCodeBody
import com.picme.session
import com.picme.views.ViewInviteInfo
import com.picme.views.qrDisplay


class QRCodeDetail(val qrCodeInfo: Readable<ViewInviteInfo>, val onBack: suspend () -> Unit) : PicmeDialog() {
    override val title = shared { qrCodeInfo().inviteCode.name }
    override val leftAlignIfLarge = true
    override fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = title,
        onBack = onBack,
    )

    override fun ViewWriter.content() {
        qrDisplay(qrCodeInfo) {
            formCol {
                sizeConstraints(height = 0.dp) - space()
                centered - stack {
                    ::exists{ !qrCodeInfo().inviteCode.temporarilyDisabled }
                    centered - compact - danger - button {
                        text { content = "Pause Invitation" }
                        onClick {
                            showConfirmDialog(
                                title = "Confirm Pause",
                                content = "This Invitation will be paused.",
                                confirmLabel = "Pause",
                                onConfirm = {
                                    session.awaitNotNull().collection2.patchInviteCode(
                                        qrCodeInfo().inviteCode.inviteCodeId,
                                        PatchInviteCodeBody(temporarilyDisabled = true)
                                    )
                                    onBack()
                                },
                            )
                        }
                    }
                }
                centered - stack {
                    ::exists{ qrCodeInfo().inviteCode.temporarilyDisabled }
                    centered - compact - danger - button {
                        text { content = "Enable Invitation" }
                        onClick {
                            showConfirmDialog(
                                title = "Confirm Enable",
                                content = "This Invitation will be enabled.",
                                confirmLabel = "Enable",
                                onConfirm = {
                                    session.awaitNotNull().collection2.patchInviteCode(
                                        qrCodeInfo().inviteCode.inviteCodeId,
                                        PatchInviteCodeBody(temporarilyDisabled = false)
                                    )
                                    onBack()
                                },
                            )
                        }
                    }
                }
            }
            space {}
        }
    }
}
