package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*

enum class ScreenType1 { LargeScreen, CollectionLargeScreen, SmallScreen }

abstract class PicmeDialog : Screen {

    open val withinCollContext = false
    open val alwaysLarge = false

    final override fun ViewWriter.render() {
        val constrainWidth = 32.rem

        // TODO: Switch to reactive size constraints in a future release after this feature is added to KiteUI
        unpadded - expanding - stack {
            swapView {
                swapping(
                    current = label@{
                        if (isSmallScreen() || alwaysLarge) {
                            return@label ScreenType1.SmallScreen
                        }
                        if (withinCollContext) {
                            return@label ScreenType1.CollectionLargeScreen
                        }
                        ScreenType1.LargeScreen
                    },
                    views = {
                        when (it) {
                            ScreenType1.LargeScreen -> appMaterialTheme.onNext - expanding - col {
                                atTopCenter - sizeConstraints(width = constrainWidth) - expanding - col {
                                    padded - topBar { close() }
                                    unpadded - content()
                                }
                            }

                            ScreenType1.CollectionLargeScreen -> appMaterialTheme.onNext - expanding - col {
                                spacing = 0.dp
                                sizeConstraints(width = constrainWidth) - expanding - col {
                                    spacing = 0.dp
                                    padded - topBar { close() }
                                    unpadded - content()
                                }
                            }

                            ScreenType1.SmallScreen -> appMaterialTheme.onNext - unpadded - col {
                                padded - topBar { close() }
                                unpadded - content()
                            }
                        }
                    }
                )
            }
        }
    }

    suspend fun ViewWriter.close() {
        val visibleAsDialog = dialogScreenNavigator.currentScreen.awaitOnce() == this@PicmeDialog
        val visibleAsScreen = screenNavigator.currentScreen.awaitOnce() == this@PicmeDialog
        if (visibleAsDialog) dialogScreenNavigator.dismiss()
        if (visibleAsScreen) screenNavigator.goBack()
    }

    abstract fun ViewWriter.content()

    open fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = title,
        onBack = close,
    )
}

fun ViewWriter.dialogTopBar(title: Readable<String>, onBack: suspend () -> Unit) = col {
    spacing = 0.5.rem
    row {
        centered - actionButtonNoText(PIcon.chevronleft) { onBack() }
        centered - h3 { ::content { title() } }
        expanding - space {}
    }
}