package com.picme.sdk2

import com.lightningkite.kiteui.Async
import com.lightningkite.kiteui.asyncGlobal
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.picme.logout
import com.picme.sdk2.caching.*
import com.picme.sdk2.generated.ApiEndpoints
import com.picme.sdk2.generated.ad.AdHandlerApi
import com.picme.sdk2.generated.ad.AdHandlerApiLive
import com.picme.sdk2.generated.authentication.AuthenticationHandlerApiLive
import com.picme.sdk2.generated.authentication.UserAuthenticated
import com.picme.sdk2.generated.authentication.UserData
import com.picme.sdk2.generated.collection2.CollectionHandler2ApiLive
import com.picme.sessionRefreshToken
import com.picme.views.Environment
import com.picme.views.defaultBackendUrl
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

class Session(
    val remoteApi: ApiEndpoints,
    val initAuth: UserAuthenticated,
    initUserData: UserData,
) {

    init {
        asyncGlobal { sessionRefreshToken set initAuth.refreshToken }
    }

    private var _authenticatedUser: Property<UserData> = Property(initUserData)
    val authenticatedUser: Readable<UserData> get() = _authenticatedUser

    private val firstAuthExpiration = now().plus(initAuth.expiresInSeconds.seconds)
    private lateinit var accessTokenCached: Async<String>
    private var accessTokenLoading = false
    private var accessTokenExpiresAt: Instant = Instant.DISTANT_PAST
    private fun startLoadAccessTokenIfNeeded() {
        if (now() > accessTokenExpiresAt && !accessTokenLoading) {
            accessTokenLoading = true
            accessTokenCached = asyncGlobal {
                try {
                    val result = remoteApi.authenticationHandler.refreshUserAuthentication(initAuth.refreshToken).let {
                        it.successfulAuthentication ?: throw Exception("Failed to refresh token")
                    }
                    _authenticatedUser.value = result.authenticatedUser
                    accessTokenExpiresAt = now() + result.authenticated.expiresInSeconds.seconds - 5.seconds
                    accessTokenLoading = false
                    return@asyncGlobal result.authenticated.accessToken
                } catch (e: Exception) {
                    logout()
                    throw e
                }
            }
        }
    }

    suspend fun accessToken(): String {
        if (now() < firstAuthExpiration) {
            return initAuth.accessToken
        }
        startLoadAccessTokenIfNeeded()
        return accessTokenCached.await()
    }


    val collection2: CollectionHandler2ApiCacheable =
        CollectionHandler2ApiCacheableLive2(
            CollectionHandler2ApiLive(remoteApi.collectionHandler2, this::accessToken),
            userId = { _authenticatedUser.value.userId },
            externalStorage = ExternalStorage.Test(),
//            externalStorage = ExternalStorage.PlatformCache(),
            cacheLife = if (defaultBackendUrl == Environment.Dev) 4.minutes else 59.minutes
        )
    val authentication: AuthHandlerApiCacheable =
        AuthenticationHandlerCache(AuthenticationHandlerApiLive(remoteApi.authenticationHandler, this::accessToken))
    val ad: AdHandlerApi = AdHandlerApiLive(remoteApi.adHandler, this::accessToken)
}
