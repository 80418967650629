package com.picme.components

import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.picme.FatCardSemantic
import com.picme.dismissBackgroundTheme


fun ViewWriter.smallDialog(title: String? = null, setup: ViewWriter.() -> Unit) {
    dismissBackgroundTheme - dismissBackground {
        centered - FatCardSemantic.onNext - sizeConstraints(width = 32.rem) - expanding - col {
            spacing = 2.rem
            centered - h4 {
                ::exists { title != null }
                content = title ?: ""
            }
            setup()
        }
    }
}