package com.picme.views.share

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.session
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionUserData

fun String.anonymousIfEmpty(): String = this.ifEmpty { "Anonymous" }

fun String.userIconColor(): Color {
    val notEmpty = this.anonymousIfEmpty()
    val hash = notEmpty.hashCode()
    val r = (hash shr 16) and 0xFF
    val g = (hash shr 8) and 0xFF
    val b = hash and 0xFF
    return Color.fromHexString("#${r.toString(16)}${g.toString(16)}${b.toString(16)}")
}

class Guests(val collectionId: CollectionId) : Screen {

    override fun ViewWriter.render() {
        expanding - unpadded - stack {
            val sharees = Property<List<CollectionUserData>>(emptyList())
            val loading = Property(false)

            launch {
                loading set true
                val coll = session.awaitNotNull().collection2.getCollectionLive(collectionId)

                session.awaitNotNull().collection2.listUsers(collectionId).let {
                    sharees set it.users.filterNot { it.userId == coll().collection.creatorUserId }
                        .sortedByDescending { it.participationRights.value }
                }
                loading set false
            }

            padded - col {
                ::exists { loading() }
                space(); space();space()
                centered - activityIndicator()
            }
            centered - padded - text {
                content = "There are no guests."
                ::exists { !loading() && sharees().isEmpty() }
            }


            expanding - recyclerView {
                ::exists { !loading() && sharees().isNotEmpty() }
                children(sharees) { guest ->
                    col {
                        stack {
                            atStart - guestIconAndName(guest)
                            gravity(Align.End, Align.Center) - iconButtonThemeNotCentered - menuButton {
                                requireClick = true
                                centered - icon { source = PIcon.more }
                                preferredDirection = PopoverPreferredDirection.belowLeft
                                opensMenu {
                                    popoverWrapper {
                                        popoverItem {
                                            icon.source = PIcon.unlink
                                            text.content = "Disconnect"
                                            button.onClick {
                                                val g = guest()
                                                closePopovers()
                                                showConfirmDialog(
                                                    title = "Disconnect Collection",
                                                    content = "Are you sure you want to disconnect ${if (g.name.isBlank()) g.name else "this guest"} from this collection?",
                                                    warning = "This action cannot be undone.",
                                                    confirmLabel = "Disconnect",
                                                    onConfirm = {
                                                        session.awaitNotNull().collection2.revokeRights(
                                                            collectionId,
                                                            g.userId
                                                        )
                                                        sharees set (sharees().filter { it.userId != g.userId })
                                                    }
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        sizeConstraints(height = 0.25.rem) - space()
                    }
                }
            }
        }
    }
}

fun ViewWriter.guestIconAndName(guest: Readable<CollectionUserData>) {
    row {
        sizeConstraints(
            width = 3.rem,
            height = 3.rem
        ) - centered - buttonTheme - padded - stack {
            dynamicTheme {
                val bg = guest().name.userIconColor()
                ThemeDerivation {
                    it.copy(
                        background = bg,
                        foreground = Color.white
                    ).withBack
                }
            }
            centered - h6 { ::content { guest().name.anonymousIfEmpty().take(1) } }
        }
        centered - col {
            spacing = 0.rem
            h4 { ::content { guest().name.anonymousIfEmpty() } }
            fadedForeground.onNext - text {
                ::content { guest().participationRights.rightsOnCollection() }
            }
        }
    }
}
