package com.picme.actuals

import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.direct.animate
import kotlinx.browser.window
import kotlinx.dom.addClass
import org.w3c.dom.HTMLElement
import kotlin.js.json


actual fun RView.animatePulsating() {
    native.onElement {
        it as HTMLElement
        it.animate(
            arrayOf(
                json("transform" to "none"),
                json("transform" to "scale(1.1)"),
                json("transform" to "none"),
            ), json(
                "duration" to 1500.0,
                "easing" to "linear",
                "iterations" to 1000
            )
        )
    }
}

actual fun RView.disableContextMenu() {
    native.onElement {
        it as HTMLElement
        it.oncontextmenu = {
            it.preventDefault()
        }
        it.addClass("disableContextMenu")
    }
}

actual fun deviceCanShareFiles(): Boolean {
    return !!window.navigator.asDynamic().canShare
}
actual fun webUrl(): String? {
    return window.location.href
}