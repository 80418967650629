package com.picme.components

import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.ContainingView
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.stack


fun ViewWriter.popoverWrapper(
    width: Dimension? = null, height: Dimension? = null,
    content: ContainingView.() -> Unit
) = sizeConstraints(width = width, height = height) - stack {
    content()
}