package com.picme.layout


import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.views.CollSubPage
import com.picme.views.CollectionLanding
import com.picme.views.trashActions


fun ViewWriter.toolbar() {
    val showToolbar = shared {
        val curr = screenNavigator.currentScreen()
        if (curr is NoTopBar) return@shared false
        if (curr is IncludeTopBar) return@shared true

        if (curr is CollectionLanding) return@shared (session()?.isVerifiedAccount() == true)

        (curr !is UnauthScreen && curr !is PicmeDialog)
    }
    bar - row {
        ::exists { showToolbar() && currentCollection() != null }
        spacing = 0.75.rem
        collectionListMenu()


        val title = shared { screenNavigator.currentScreen()?.title?.await() ?: "..." }


        expanding - centered - h4 {
            ::exists { title().isNotEmpty() }
            ::content { title() }
        }

        centered - stack {
            ::exists{ title().isEmpty() }
            picmeIconDisplay()
        }

        val actionSection = shared {
            if (isSmallScreen()) ActionSection.None
            else if (CollectionState.currView() == CollSubPage.Trash) ActionSection.Trash
            else ActionSection.Collection
        }

        unpadded - stack {
            ::exists { actionSection() != ActionSection.None }
            spacing = 0.rem
            swapView {
                swapping(
                    current = { actionSection() },
                    views = {
                        when (it) {
                            ActionSection.Collection -> collectionButtonsTop()
                            ActionSection.Trash -> trashActions(false)
                            ActionSection.None -> {}
                        }
                    },
                )
            }
        }

        atEnd - userInfoPopover()
    }


    bar - row {
        ::exists { showToolbar() && currentCollection() == null }
        spacing = 0.75.rem
        collectionListMenu()

        expanding - space { }
        centered - stack {
            picmeIconDisplay()
        }

        expanding - space { }
        userInfoPopover()
    }
    separatorTheme - separator { ::exists { showToolbar() } }
}


enum class ActionSection { Collection, Trash, None }


