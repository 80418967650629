package com.picme.sdk2.caching

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.ImageRemote
import com.lightningkite.kiteui.reactive.LateInitProperty
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.shared
import com.picme.imageIfExists
import com.picme.sdk2.generated.authentication.AuthenticationHandlerApi
import com.picme.sdk2.generated.authentication.GetMyUserInfoResponse
import com.picme.sdk2.generated.authentication.SetUserAttributesResponse
import kotlinx.datetime.Clock
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.minutes

interface AuthHandlerApiCacheable : AuthenticationHandlerApi {
    suspend fun putProfilePicture(file: FileReference)
    val profileImage: Readable<ImageRemote?>
}

class AuthenticationHandlerCache(
    val basedOn: AuthenticationHandlerApi,
    val clock: Clock = Clock.System,
) : AuthHandlerApiCacheable, AuthenticationHandlerApi by basedOn {

    private var cacheLife = 5.minutes
    private var lastUserFetch = Instant.DISTANT_PAST
    private val lastUser = LateInitProperty<GetMyUserInfoResponse>()

    override val profileImage: Readable<ImageRemote?> = shared {
        getMyUserInfo().profilePicture.imageIfExists()
    }


    override suspend fun getMyUserInfo(): GetMyUserInfoResponse {
        return if (clock.now() - lastUserFetch > cacheLife) {
            lastUserFetch = now()
            val result = basedOn.getMyUserInfo()
            lastUser.value = result
            result
        } else lastUser.await()
    }

    override suspend fun putProfilePicture(file: FileReference) {
        val uploadInfo = getUserProfileUploadUrl(file.mimeType())
        fetch(
            uploadInfo.profilePictureLocation,
            HttpMethod.PUT,
            body = RequestBodyFile(file)
        )
        delay(8000)
        lastUserFetch = Instant.DISTANT_PAST
        getMyUserInfo()
    }

    override suspend fun deleteUserProfilePicture() {
        return basedOn.deleteUserProfilePicture().also {
            delay(5000)
            lastUserFetch = Instant.DISTANT_PAST
            getMyUserInfo()
        }
    }

    override suspend fun setUserAttributes(name: String?, setTosRead: Boolean): SetUserAttributesResponse {
        return basedOn.setUserAttributes(name, setTosRead).also {
            lastUser.state.onSuccess {
                lastUser.value = it.copy(
                    userData = it.userData.copy(
                        name = name ?: it.userData.name,
                        termsOfServiceRead = if (setTosRead) true else it.userData.termsOfServiceRead,
                    )
                )
            } ?: run {
                lastUserFetch = Instant.DISTANT_PAST
            }
        }
    }
}
