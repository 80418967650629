package com.picme.sdk2.generated.collection2

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object CollectionHandler2EndpointsSerializer : KSerializer<CollectionHandler2Endpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("CollectionHandler2Api", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: CollectionHandler2Endpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): CollectionHandler2Endpoints {
        val string = decoder.decodeString()
        return CollectionHandler2Endpoints(string)
    }
}


@Serializable(with = CollectionHandler2EndpointsSerializer::class)
data class CollectionHandler2Endpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Gets both full metadata and a URI for the "details" version of a deleted upload.
            If the upload has not been deleted, this function will throw an exception.
            Requires <see cref="F:PicMeAuth.Rights.Read" /> and <see cref="F:PicMeAuth.Rights.Delete" /> participation rights on the collection. **/
    suspend fun getDeletedUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        accessToken: suspend () -> String? = { null }
    ): GetDeletedUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/deletedUpload/${uploadId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Lists links from the specified collection.
            Requires ownership of the collection. **/
    suspend fun listLinksFrom(
        linkRelationshipType: LinkRelationshipType,
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): ListLinksToResponse2 = fetch(
        url = "$url/linksFrom/${linkRelationshipType.toParamString()}/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Deletes the cover photo for a collection.
            Requires <see cref="F:PicMeAuth.Rights.Update" /> rights on the collection. **/
    suspend fun deleteCollectionCoverPhoto(
        collectionId: CollectionId,
        photoId: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/collection/${collectionId.toParamString()}/coverPhoto/${photoId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Lists links to the specified collection.
            Requires ownership of the collection. **/
    suspend fun listLinksTo(
        linkRelationshipType: LinkRelationshipType,
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): ListLinksToResponse2 = fetch(
        url = "$url/linksTo/${linkRelationshipType.toParamString()}/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Gets both full metadata and a URI for the "details" version of the upload.
            If the upload has been deleted, this function will throw an exception.
            If the user is not the user who created the upload, requires <see cref="F:PicMeAuth.Rights.Read" /> participation rights on the collection uploads. **/
    suspend fun getUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        accessToken: suspend () -> String? = { null }
    ): GetUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload/${uploadId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Patches an existing upload with new data (at least the parts that are editable).
            Requires <see cref="F:PicMeAuth.Rights.Update" /> participation rights on the collection uploads. **/
    suspend fun patchUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        body: PatchUploadBody,
        accessToken: suspend () -> String? = { null }
    ): PatchUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload/${uploadId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Deletes an upload from a collection.
            Requires <see cref="F:PicMeAuth.Rights.Delete" /> participation rights on the collection. **/
    suspend fun deleteUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        accessToken: suspend () -> String? = { null }
    ): DeleteUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload/${uploadId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Copies the specified source upload from the source collection to the specified destination collection.
            If the user is not the user who created the upload, requires <see cref="F:PicMeAuth.Rights.Read" /> participation rights on the source collection uploads.
            Requires <see cref="F:PicMeAuth.Rights.Create" /> participation rights on the destination collection uploads.
            If an upload already with the specified identified hash already exists and <paramref name="allowDuplicates" /> is false, an <see cref="T:NetworkExceptions.AlreadyExistsException" /> will be thrown and this exception will contain identifiers for the duplicate upload in case the client wants to show it to the user. **/
    suspend fun copyUpload(
        caption: String?,
        sourceCollectionId: CollectionId,
        sourceUploadId: UploadId,
        destinationCollectionId: CollectionId,
        anonymous: Boolean,
        allowDuplicates: Boolean,
        accessToken: suspend () -> String? = { null }
    ): CopyUploadResponse2 = fetch(
        url = "$url/collection/${destinationCollectionId.toParamString()}/upload/from",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "caption" to caption.toParamString(),
            "sourceCollectionId" to sourceCollectionId.toParamString(),
            "sourceUploadId" to sourceUploadId.toParamString(),
            "anonymous" to anonymous.toParamString(),
            "allowDuplicates" to allowDuplicates.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the collection modification stamp, which changes every time the collection is updated. **/
    suspend fun getCollectionModificationStamp(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): GetCollectionModificationStampResponse2 = fetch(
        url = "$url/collectionModificationStamp/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Puts a new cover photo for a collection.
            Requires <see cref="F:PicMeAuth.Rights.Update" /> rights on the collection. **/
    suspend fun putCollectionCoverPhoto(
        collectionId: CollectionId,
        contentType: String,
        accessToken: suspend () -> String? = { null }
    ): PutCollectionCoverPhotoResponse = fetch(
        url = "$url/collection/${collectionId.toParamString()}/coverPhoto/urls",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "contentType" to contentType.toParamString(),
        ).filter { it.value != "null" },
    )
/** Lists deleted uploads in a collection.
            Requires <see cref="F:PicMeAuth.Rights.Delete" /> and <see cref="F:PicMeAuth.Rights.List" /> participation rights on the collection. **/
    suspend fun listDeletedUploads(
        collectionId: CollectionId,
        continuation: String?,
        uploadsPerPage: Int32?,
        accessToken: suspend () -> String? = { null }
    ): ListDeletedUploadsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/deletedUpload",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "continuation" to continuation.toParamString(),
            "uploadsPerPage" to uploadsPerPage.toParamString(),
        ).filter { it.value != "null" },
    )
/** Restores one or more deleted uploads to a collection.
            Requires <see cref="F:PicMeAuth.Rights.Delete" /> participation rights on the collection. **/
    suspend fun restoreDeletedUploads(
        collectionId: CollectionId,
        uploadIds: List<UploadId>?,
        accessToken: suspend () -> String? = { null }
    ): RestoreDeletedUploadsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/deletedUpload",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "uploadIds" to uploadIds.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets an existing or starts a new zip of the originals for the specified uploads in the collection.
            Requires <see cref="F:PicMeAuth.Rights.List" /> and <see cref="F:PicMeAuth.Rights.Read" /> participation rights on the collection and <see cref="F:PicMeAuth.Rights.List" /> and <see cref="F:PicMeAuth.Rights.Read" /> rights on the collection. **/
    suspend fun getOrStartPartialZipOfOriginals(
        collectionId: CollectionId,
        uploadIds: List<UploadId>,
        accessToken: suspend () -> String? = { null }
    ): GetOrStartPartialZipOfOriginalsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/zip/partial",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "uploadIds" to uploadIds.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets an existing or starts a new zip of the originals for all the uploads in the collection.
            Requires <see cref="F:PicMeAuth.Rights.List" /> and <see cref="F:PicMeAuth.Rights.Read" /> participation rights on the collection and <see cref="F:PicMeAuth.Rights.List" /> and <see cref="F:PicMeAuth.Rights.Read" /> rights on the collection. **/
    suspend fun getOrStartFullZipOfOriginals(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): GetOrStartFullZipOfOriginalsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/zip/full",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Connects a new sharee user to a collection using a legacy externally-communicated collection sharing authorization code. **/
    suspend fun sharingConnect(
        authorization: CollectionSharingAuthorizationCode,
        collectionId: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/collection/${collectionId.toParamString()}/sharees",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "authorization" to authorization.toParamString(),
        ).filter { it.value != "null" },
    )
/** Creates a new upload and gets a <see cref="T:System.Uri" /> to PUT the binary data.  Fails if an upload with the specified hash already exists.
            Requires <see cref="F:PicMeAuth.Rights.Create" /> participation rights on the collection uploads.
            If an upload already with the specified identified hash already exists and <paramref name="allowDuplicates" /> is false, an <see cref="T:NetworkExceptions.AlreadyExistsException" /> will be thrown and this exception will contain identifiers for the duplicate upload in case the client wants to show it to the user. **/
    suspend fun createUpload(
        collectionId: CollectionId,
        body: CreateUploadBody,
        allowDuplicates: Boolean,
        accessToken: suspend () -> String? = { null }
    ): CreateUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "allowDuplicates" to allowDuplicates.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )
/** Lists the uploads in a collection.
            Requires <see cref="F:PicMeAuth.Rights.List" /> participation rights on the collection uploads. **/
    suspend fun listUploads(
        collectionId: CollectionId,
        continuation: String?,
        uploadsPerPage: Int32?,
        accessToken: suspend () -> String? = { null }
    ): ListUploadsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "continuation" to continuation.toParamString(),
            "uploadsPerPage" to uploadsPerPage.toParamString(),
        ).filter { it.value != "null" },
    )
/** Deletes all uploads from a collection.
            Requires <see cref="F:PicMeAuth.Rights.Delete" /> participation rights on the collection. **/
    suspend fun deleteAllUploads(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): DeleteAllUploadsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Connects a new guest user to a collection using a legacy externally-communicated upload request authorization code. **/
    suspend fun requestConnect(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/collection/${collectionId.toParamString()}/guests",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Gets the rights the calling user has on the collection. **/
    suspend fun getCollectionRights(
        collectionId: CollectionId,
        targetUserId: UserId?,
        accessToken: suspend () -> String? = { null }
    ): GetCollectionRightsResponse2 = fetch(
        url = "$url/collectionRights/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "targetUserId" to targetUserId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Revokes rights to a collection that were previously granted to a user by a Link (probably through a Invite code).
            The caller may be the owner, and may specify the user whose rights should be revoked,
            or the caller may be the user who wants their own rights to the collection revoked. **/
    suspend fun revokeRights(
        collectionId: CollectionId,
        userId: UserId,
        accessToken: suspend () -> String? = { null }
    ): RevokeRightsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/rights",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
            "userId" to userId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets information about the specified collection. **/
    suspend fun getCollection(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): GetCollectionResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Patches an existing collection. **/
    suspend fun patchCollection(
        collectionId: CollectionId,
        body: PatchCollectionBody,
        accessToken: suspend () -> String? = { null }
    ): PatchCollectionResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Deletes a collection and all descendant collections. **/
    suspend fun deleteCollection(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): DeleteCollectionResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Activates the specified Invite code.
            Any authenticated user can activate a Invite code.
            On activation,
            1. the start(s)/end(s) are checked first
            2. activation attempt is recorded by either user id or activation time, depending on which we are limited on, by activation time if not limited, with the limit being checked afterwards
            3. link template is expanded and links are created
            4. the post activation target is navigated to
            
            Link activation will enable the following scenarios:
            1. Link the activator as having been referred by another user (and possibly allow them to create their own full account)
            2. Add the activator as a "friend" of another user
            3. Add the activator as an upload-only user of a collection
            4. Add the activator as a guest with specific rights to a collection
            
            We may add other properties to the code to perform more complex actions. **/
    suspend fun activateInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): ActivateInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}/activate",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Deprecated.  Generates a QR code from the specified parameters.  Currently not authenticated, but the TLD must be picme.com or localhost, so it shouldn't be particularly useful for third parties. **/
    suspend fun generateQrCodePng(
        hostnamePrefix: String,
        pathAndQueryStringPattern: String,
        useLogo: Boolean,
        colorLogo: Boolean,
        pixels: Int32,
        accessToken: suspend () -> String? = { null }
    ): CreateQrCodeImageResponse = fetch(
        url = "$url/pngqr64/${hostnamePrefix.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "pathAndQueryStringPattern" to pathAndQueryStringPattern.toParamString(),
            "useLogo" to useLogo.toParamString(),
            "colorLogo" to colorLogo.toParamString(),
            "pixels" to pixels.toParamString(),
        ).filter { it.value != "null" },
    )
/** Lists all users who have activated the specified Invite code.
            Note that this includes users who activated the code and then had their rights revoked.
            Requires ownership of the Invite code. **/
    suspend fun listActivatedUsers(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): ListActivatedUsersResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}/users",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Lists users that have access to the specified collection (both owners and participants).
            Requires ownership of the collection. **/
    suspend fun listUsers(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): ListUsersResponse2 = fetch(
        url = "$url/users/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Generates a QR code with a logo from the specified parameters.  Currently not authenticated, but the TLD must be picme.com or localhost, so it shouldn't be particularly useful for third parties. **/
    suspend fun generateQrCodeSvg(
        hostnamePrefix: String,
        pathAndQueryStringPattern: String,
        useLogo: Boolean,
        colorLogo: Boolean,
        accessToken: suspend () -> String? = { null }
    ): CreateQrCodeImageResponse = fetch(
        url = "$url/qr64/${hostnamePrefix.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "pathAndQueryStringPattern" to pathAndQueryStringPattern.toParamString(),
            "useLogo" to useLogo.toParamString(),
            "colorLogo" to colorLogo.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the specified Invite code object.
            Any authenticated user can call this API, whether they're related to the Invite code or its owner or not. **/
    suspend fun getInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): GetInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Updates a Invite code object from the specified parameters.
            Only the Invite code's creator can patch it. **/
    suspend fun patchInviteCode(
        inviteId: InviteCodeId,
        body: PatchInviteCodeBody,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Deletes the specified Invite code object.
            Only the Invite code's creator can delete it. **/
    suspend fun deleteInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Creates a Invite code object for requesting uploads to a collection.
            The Invite code will be owned by the calling user and will be hierarchically linked to the specified collection.
            The link type for the activated link is <see cref="P:SimpleDatabase.LinkRelationshipType.ParticipationRights" /> with a link relationship of type <see cref="T:PicMeAuth.Rights" />. **/
    suspend fun createRequestInviteCode(
        collectionGlobalId: RecordGlobalId,
        name: String,
        clientInformation: String,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/requestInvite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionGlobalId" to collectionGlobalId.toParamString(),
            "name" to name.toParamString(),
            "clientInformation" to clientInformation.toParamString(),
        ).filter { it.value != "null" },
    )
/** Creates a Invite code object for requesting uploads to a collection.
            The Invite code will be owned by the calling user and will be hierarchically linked to the specified collection.
            The link type for the activated link is <see cref="P:SimpleDatabase.LinkRelationshipType.ParticipationRights" /> with a link relationship of type <see cref="T:PicMeAuth.Rights" />. **/
    suspend fun createSharingInviteCode(
        collectionGlobalId: RecordGlobalId,
        name: String,
        clientInformation: String,
        rightsToOtherUsersUploads: Rights,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/sharingInvite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionGlobalId" to collectionGlobalId.toParamString(),
            "name" to name.toParamString(),
            "clientInformation" to clientInformation.toParamString(),
            "rightsToOtherUsersUploads" to rightsToOtherUsersUploads.toParamString(),
        ).filter { it.value != "null" },
    )
/** Creates a new collection. **/
    suspend fun createCollection(
        body: CreateCollectionBody,
        accessToken: suspend () -> String? = { null }
    ): CreateCollectionResponse2 = fetch(
        url = "$url/collection",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Lists collections the authenticated user owns or has access to.
            Upgrades old owned collections to the new format if needed. **/
    suspend fun listCollections(
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
        accessToken: suspend () -> String? = { null }
    ): ListCollectionsResponse2 = fetch(
        url = "$url/collection",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "itemsPerPage" to itemsPerPage.toParamString(),
            "continuation" to continuation.toParamString(),
            "forUserId" to forUserId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Records an ad impression (an ad that was seen for a certain amount of time in a certain context by an end user). **/
    suspend fun recordImpression(
        adId: String,
        collectionId: String?,
        contextId: String?,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/impression",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "adId" to adId.toParamString(),
            "collectionId" to collectionId.toParamString(),
            "contextId" to contextId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Creates a Invite code object from the specified parameters.
            Any authenticated user can create a Invite code, but the Invite code has to link something, and the caller must own whatever is being linked. **/
    suspend fun createInviteCode(
        body: CreateInviteCodeBody,
        linkParentRecord: RecordGlobalId?,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/invite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "linkParentRecord" to linkParentRecord.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )
/** Lists invite codes owned by the specified user (or the authenticated user), or associated with a specified collection.
            The caller must be the owner of the Invite code or must own the contained entity.
            With a <paramref name="linkRelationshipType" /> of <see cref="P:SimpleDatabase.LinkRelationshipType.Hierarchy" /> and a <paramref name="linkPrimaryGlobalId" /> for a collection, this will list all the request/sharing invite codes associated with the collection, both request invite codes and sharing invite codes.
            With a <paramref name="linkRelationshipType" /> of <see cref="P:PicMeAuth.AuthLinkRelationshipTypes.ReferralLinkType" /> and a <paramref name="linkPrimaryGlobalId" /> for user, this will list all the referral invite codes used by the specified user to refer others. **/
    suspend fun listInviteCodes(
        linkRelationshipType: LinkRelationshipType?,
        linkPrimaryGlobalId: RecordGlobalId?,
        accessToken: suspend () -> String? = { null }
    ): ListInviteCodesResponse = fetch(
        url = "$url/invite",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "linkRelationshipType" to linkRelationshipType.toParamString(),
            "linkPrimaryGlobalId" to linkPrimaryGlobalId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Lists possible ads to use for future ad impressions. **/
    suspend fun listAds(
        collectionId: String?,
        unitTest: Boolean,
        accessToken: suspend () -> String? = { null }
    ): ListAdsResponse = fetch(
        url = "$url/ads",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionId" to collectionId.toParamString(),
            "unitTest" to unitTest.toParamString(),
        ).filter { it.value != "null" },
    )
/** Serves up an ad of the specified size. **/
    suspend fun adServe(
        collectionId: String?,
        adSize: AdSize?,
        contextId: String?,
        unitTest: Boolean,
        accessToken: suspend () -> String? = { null }
    ): RedirectResponse = fetch(
        url = "$url/ad",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionId" to collectionId.toParamString(),
            "adSize" to adSize.toParamString(),
            "contextId" to contextId.toParamString(),
            "unitTest" to unitTest.toParamString(),
        ).filter { it.value != "null" },
    )
}

