package com.picme.components

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.TextField
import com.lightningkite.kiteui.views.direct.stack
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.navigator
import com.picme.*
import com.picme.sdk2.generated.collection2.CreateCollectionBody
import com.picme.session
import com.picme.sdk2.toSafeEncoded
import com.picme.views.CollectionDetail
import com.picme.views.loginOrSignupButton


@Routable("create-collection")
object CreateCollection : Screen {
    private val collName = Property("")
    override fun ViewWriter.render() = smallDialog("Create Collection") {
        formCol {
            ::exists { !requireVerifiedAccounts || session()?.isVerifiedAccount() == true }

            val tf: TextField
            styledTextField {
                label = "Collection name"
                content bind collName
                hint = "Name"
                tf = this.field
                field.requestFocus()
            }

            actionOrCancelSection(
                onCancel = { dialogScreenNavigator.clear() },
                actionButton = {
                    importantButton("Continue", field = tf,
                        enabled = shared { collName.await().isNotEmpty() }) {
                        // Silently create an unverified account when creating a collection without authentication
                        createAccountIfNewUser()

                        val created = session.awaitNotNull().collection2.createCollection(
                            CreateCollectionBody(name = collName())
                        )
                        createInitialQrCodes(created.collection)

                        collName set ""
                        dialogScreenNavigator.clear()
                        mainScreenNavigator.reset(CollectionDetail(created.collection.collectionId.raw.toSafeEncoded()))
                    }
                }
            )
        }
        centered - stack {
            ::exists { session()?.isVerifiedAccount() != true }
            eula("By clicking Continue, you agree to our")
        }
    }
}