package com.picme.views

import com.lightningkite.kiteui.PlatformStorage
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.danger
import com.lightningkite.kiteui.views.direct.TextField
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.navigator
import com.lightningkite.kiteui.views.reactiveScope
import com.picme.UnauthScreen
import com.picme.components.authScreen
import com.picme.components.importantButton
import com.picme.components.styledTextField
import com.picme.emailOrPhone
import com.picme.isValidEmailOrPhone
import com.picme.sdk2.unauthApi
import com.picme.showToast


@Routable("login-or-signup")
class LoginOrSignUp(val backArrow: Boolean = false) : Screen, UnauthScreen {
    override fun ViewWriter.render() {
        val errorText = Property<String?>(null)
        val fieldError = Property<String?>(null)

        suspend fun submitLogin() {
            // When the user's refresh token expires, sometimes this doesn't get removed. I'm not sure why
            PlatformStorage.remove("cacheKeys")
            PlatformStorage.remove("collections.cache")
            if (emailOrPhone() == "84043") {
                devSettings set true
                showToast("Dev options enabled", "You will now see the bottom action bar")
            } else if (emailOrPhone().isBlank()) {
                fieldError set "Required"
            } else if (!emailOrPhone().isValidEmailOrPhone()) {
                fieldError set "Please enter a valid email or phone number"
            } else {
                try {
                    val userStatus = unauthApi().authenticationHandler.checkUsername(emailOrPhone().lowercase())
                    if (userStatus.status != "CONFIRMED") {
                        screenNavigator.navigate(CreateAccount)
                        return
                    }
                    if (userStatus.verified) {
                        screenNavigator.navigate(LogInScreen)
                        return
                    }
                    unauthApi().authenticationHandler.resendAccountVerification(emailOrPhone().lowercase())
                    showToast("Verification code sent")
                    screenNavigator.navigate(ConfirmEmail().apply { userPassword })

                } catch (e: Exception) {
                    if (e.message?.contains("NotFound") == true) {
                        screenNavigator.navigate(CreateAccount)
                    } else {
                        errorText.value = "An error has occurred"
                    }
                }
            }
        }

        authScreen("Log in or Sign up", Constant(backArrow)) {
            col {


                danger - text {
                    ::exists { (errorText() ?: "").isNotBlank() }
                    ::content { errorText() ?: "" }
                    align = Align.Center
                }

                reactiveScope {
                    if (emailOrPhone().isValidEmailOrPhone()) fieldError set null
                }

                val tf: TextField
                styledTextField {
                    label = "Email or Phone"
                    hint = "Email or Phone"
                    content bind emailOrPhone
                    field.keyboardHints = KeyboardHints.email
                    error bind fieldError
                    tf = this.field
                    field.requestFocus()
                }

                importantButton("Continue", field = tf, onClick = ::submitLogin)

                backendUrlSelect()

            }
        }
    }
}
