package com.picme.components

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.SafeIds
import com.picme.views.CollectionDetail

enum class ScreenType { Contained, LeftAlign, Expanding }
abstract class PicmeDialog : Screen {

    open val customWidth: Dimension? = null
    open val leftAlignIfLarge = false
    open val alwaysLarge = false

    final override fun ViewWriter.render() {

        val constrainWidth = customWidth ?: 32.rem


        // TODO: Switch to reactive size constraints in a future release after this feature is added to KiteUI
        unpadded - expanding - stack {
            swapView {
                swapping(
                    current = {
                        if (isSmallScreen() || alwaysLarge) ScreenType.Expanding
                        else if (leftAlignIfLarge) ScreenType.LeftAlign
                        else ScreenType.Contained
                    },
                    views = {
                        when (it) {
                            ScreenType.Contained -> appMaterialTheme.onNext - appMaterialTheme.onNext - expanding - col {
                                atTopCenter - sizeConstraints(width = constrainWidth) - expanding - col {
                                    padded - topBar { close() }
                                    headerSeparator()
                                    padded - content()
                                }
                            }

                            ScreenType.LeftAlign -> appMaterialTheme.onNext - expanding - col {
                                spacing = 0.dp
                                sizeConstraints(width = constrainWidth) - expanding - col {
                                    spacing = 0.dp
                                    padded - topBar { close() }
                                    headerSeparator()
                                    padded - content()
                                }
                            }

                            ScreenType.Expanding -> appMaterialTheme.onNext - formCol {
                                spacing = 1.rem
                                topBar { close() }
                                headerSeparator()
                                content()
                            }
                        }
                    }
                )
            }
            overlay()

        }
    }

    suspend fun ViewWriter.close() {
        val visibleAsDialog = dialogScreenNavigator.currentScreen.awaitOnce() == this@PicmeDialog
        val visibleAsScreen = screenNavigator.currentScreen.awaitOnce() == this@PicmeDialog
        if (visibleAsDialog) dialogScreenNavigator.dismiss()
        if (visibleAsScreen) screenNavigator.goBack()
    }

    abstract fun ViewWriter.content()

    open fun ViewWriter.overlay() {}

    open fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = title,
        onBack = close,
    )

    open fun ViewWriter.headerSeparator(): ViewWrapper? = null
}

fun ViewWriter.dialogTopBar(
    title: Readable<String>,
    onBack: suspend () -> Unit,
) = col {
    spacing = 0.5.rem
    row {
        centered - actionButtonNoText(PIcon.chevronleft) { onBack() }
        centered - h3 { ::content { title() } }
        expanding - space {}
    }
}