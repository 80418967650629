package com.picme.components

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.views.CollSubPage
import com.picme.views.imageSelectedCounter

fun ViewWriter.collectionButtonsTop() = stack {
    spacing = 0.rem
    val collectionId = shared { currentCollection()?.collectionId ?: CollectionId("") }
    val weAreOwner = shared { currentCollection()?.let { ownsPCollection(it) } ?: false }
    ifElse(
        condition = { CollectionState.selectItems.isSelecting() },
        ifTrue = {
            bar - row {
                spacing = 0.rem
                selectRow(collectionId, weAreOwner)
            }
        },
        ifFalse = {
            bar - unpadded - row {
                spacing = 0.5.rem
                iconButtonTheme - button {
                    smallIcon(PIcon.select)
                    onClick {
                        CollectionState.selectItems.startSelecting()
                        CollectionState.setView(CollSubPage.Main)
                    }
                }
                iconButtonTheme - button {
                    smallIcon(PIcon.addcircle)
                    onClick {
                        CollectionState.setView(CollSubPage.Main)
                        uploadExternalFiles(collectionId())
                    }
                }
                iconButtonTheme - stack {
                    ::exists { weAreOwner() }
                    spacing = 0.dp
                    dynamicTheme {
                        if (CollectionState.currView() == CollSubPage.Guests) {
                            ThemeDerivation {
                                it.copy(
                                    background = Color.menuIconBg,
                                    cornerRadii = CornerRadii.ForceConstant(10.dp)
                                ).withBack
                            }
                        } else null
                    }
                    iconButtonTheme - button {
                        smallIcon(PIcon.picme)
                        onClick { CollectionState.toggleView(CollSubPage.Guests) }
                    }
                }
                iconButtonTheme - stack {
                    ::exists { weAreOwner() }
                    spacing = 0.dp
                    dynamicTheme {
                        if (CollectionState.currView() == CollSubPage.Settings) {
                            ThemeDerivation {
                                it.copy(
                                    id = "settings",
                                    background = Color.menuIconBg,
                                    cornerRadii = CornerRadii.ForceConstant(10.dp)
                                ).withBack
                            }
                        } else null
                    }
                    iconButtonTheme - button {
                        smallIcon(PIcon.settings)
                        onClick {
                            CollectionState.toggleView(CollSubPage.Settings)
                        }
                    }
                }
                collectionActions(
                    collectionId,
                    PopoverPreferredDirection.belowLeft,
                    setView = { CollectionState.setView(it) }
                )
            }
        }
    )
}

fun ViewWriter.collectionButtonsBottom(collection: Readable<PCollection>) = stack {
    ::exists { !AppState.softInputOpen() }
    spacing = 0.rem
    val collectionId = shared { collection().collectionId }
    val weAreOwner = shared { ownsPCollection(collection()) }
    ifElse(
        condition = { CollectionState.selectItems.isSelecting() },
        ifTrue = {
            bar - row {
                spacing = 0.75.rem
                selectRow(collectionId, weAreOwner)
            }
        },
        ifFalse = {
            bar - row {
                spacing = 0.75.rem
                iconButtonTheme - button {
                    smallIcon(PIcon.select)
                    onClick {
                        CollectionState.selectItems.startSelecting()
                        CollectionState.setView(CollSubPage.Main)
                    }
                }
                expanding - space(0.0)
                iconButtonTheme - button {
                    smallIcon(PIcon.addcircle, "upload")
                    onClick {
                        CollectionState.setView(CollSubPage.Main)
                        uploadExternalFiles(collectionId())
                    }
                }
                expanding - space(0.0) { ::exists { weAreOwner() } }
                stack {
                    ::exists { weAreOwner() }
                    centered - iconButtonTheme - ThemeDerivation {
                        it.copy(cornerRadii = CornerRadii.Constant(10.dp)).withBack
                    }.onNext - button {
                        dynamicTheme {
                            if (CollectionState.currView() == CollSubPage.Guests) {
                                ThemeDerivation {
                                    it.copy(background = Color.menuIconBg).withBack
                                }
                            } else null
                        }
                        spacing = 5.dp
                        centered - sizeConstraints(width = 2.8.rem, height = 2.8.rem) - image {
                            source = Vectors.logoWithWords
                        }
                        onClick {
                            CollectionState.toggleView(CollSubPage.Guests)
                        }
                    }
                }

                expanding - space(0.0) { ::exists { weAreOwner() } }
                centered - iconButtonTheme - stack {
                    ::exists { weAreOwner() }
                    spacing = 0.dp
                    dynamicTheme {
                        if (CollectionState.currView() == CollSubPage.Settings) {
                            ThemeDerivation {
                                it.copy(
                                    background = Color.menuIconBg,
                                    cornerRadii = CornerRadii.Constant(10.dp),
                                ).withBack
                            }
                        } else null
                    }
                    iconButtonTheme - button {
                        smallIcon(PIcon.settings)
                        onClick { CollectionState.toggleView(CollSubPage.Settings) }
                    }
                }
                expanding - space(0.0)
                collectionActions(
                    collectionId,
                    PopoverPreferredDirection.aboveLeft,
                    setView = { CollectionState.setView(it) }
                )
            }
        }
    )
    atTop - separatorTheme - separator { }
}


fun ViewWriter.selectRow(collectionId: Readable<CollectionId>, weAreOwner: Readable<Boolean>) {
    val imagePaging = shared {
        session.awaitNotNull().collection2.listUploadsLive(collectionId())
    }
    imageSelectedCounter(CollectionState.selectItems)
    expanding - space {}
    val imgsAreSelected = shared { CollectionState.selectItems.selected().isNotEmpty() }

    actionButtonNoText(PIcon.copy, enabled = imgsAreSelected) {
        openCopyMenu(CollectionState.selectItems.selected().map { UploadId(it.id) })
    }

    space {}
    iconButtonTheme - button {
        smallIcon(PIcon.download)
        ::enabled{ imgsAreSelected() }
        onClick {
            val message = when (Platform.current) {
                Platform.Web -> "Selected items will be downloaded to your device as a zip file."
                Platform.iOS -> "Selected items will be saved to your Camera Roll."
                else -> "Selected items will be saved to your device."
            }
            showConfirmDialog("Confirm Download", message, "Download") {
                collectionDownload(collectionId(), CollectionState.selectItems.selected().map { UploadId(it.id) })
                CollectionState.selectItems.stopSelecting()
            }
        }
    }
    space {}
    actionButtonNoText(
        displayIcon = PIcon.trash,
        exists = shared { weAreOwner() || session()?.isVerifiedAccount() == false },
        enabled = imgsAreSelected
    ) {
        showConfirmDialog(
            title = "Confirm Delete",
            content = "Selected items will be deleted from the collection.",
            confirmLabel = "Delete",
            onConfirm = {
                val selected = CollectionState.selectItems.selected().map { it.id }
                val images = imagePaging().all().filter { it.uploadId.raw in selected }
                CollectionState.selectItems.stopSelecting()
                deleteImages(images)
            }
        )
    }
}

fun ViewWriter.filterButton() {
    centered - iconButtonTheme - stack {
        spacing = 0.dp
        dynamicTheme {
            if (CollectionState.currView() == CollSubPage.Filters) {
                ThemeDerivation { it.copy(background = Color.menuIconBg).withBack }
            } else null
        }

        iconButtonTheme - button {
            smallIcon(PIcon.filterfunnelon)
            ::exists { CollectionState.filtersOn() }
            onClick { CollectionState.toggleView(CollSubPage.Filters) }
        }
        iconButtonTheme - button {
            smallIcon(PIcon.filterfunnel)
            ::exists { !CollectionState.filtersOn() }
            onClick { CollectionState.toggleView(CollSubPage.Filters) }
        }
    }
}


