package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.PIcon
import com.picme.picmeToastBg
import com.picme.picmeToastGreen

fun ViewWriter.toast() = atBottomCenter - padded - stack {
    ::exists { currentToast() != null }
    card - themeFromLast {
        it.copy(
            foreground = Color.white,
            background = Color.picmeToastBg,
            outlineWidth = 0.px
        )
    } - stack {
        row {
            centered - icon {
                themeChoice += ThemeDerivation {
                    it.copy(
                        foreground = Color.picmeToastGreen,
                        iconOverride = Color.picmeToastGreen,
                        cornerRadii = CornerRadii.ForceConstant(2.rem)
                    ).withoutBack
                }
                spacing = 0.rem
                source = PIcon.select
            }
            centered - col {
                spacing = 0.rem
                h6 {
                    ::content { currentToast()?.messagePrimary ?: "..." }
                }
                text {
                    ::exists { currentToast()?.messageSecondary != null }
                    ::content {
                        currentToast()?.messageSecondary ?: "..."
                    }
                }
            }
        }
    }
}


data class CurrentToast(
    val messagePrimary: String,
    val messageSecondary: String? = null,
)


val currentToast = Property<CurrentToast?>(null)
