package com.picme.sdk2.caching

import com.lightningkite.kiteui.ConsoleRoot
import com.lightningkite.kiteui.PlatformStorage
import com.lightningkite.kiteui.clockMillis
import com.picme.compressForLocalStorage
import com.picme.sdk2.json
import com.picme.uncompressForLocalStorage
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString

interface ExternalStorage {
    fun get(key: String): String?
    fun set(key: String, value: String)
    fun remove(key: String)
    fun clear()

    class PlatformCache(val artificalSizeCap: Int = -1) : ExternalStorage {
        val log = ConsoleRoot.tag("PlatformCache")

        @Serializable
        data class Info(val lastUse: Double, val size: Int)

        val cacheKeys = HashMap<String, Info>().also {
            PlatformStorage.get("cacheKeys")
                ?.let { json.decodeFromString<Map<String, Info>>(it) }
                ?.let { data -> it.putAll(data) }
        }

        private fun persist() {
            PlatformStorage.set("cacheKeys", json.encodeToString(cacheKeys))
        }

        override fun get(key: String): String? {
            try {
                return PlatformStorage.get(key)?.let {
                    cacheKeys[key]?.copy(lastUse = clockMillis())?.let { cacheKeys[key] = it }
                    persist()
                    uncompressForLocalStorage(it)
                }
            } catch (e: Exception) {
                println("WARN: $key could not be retrieved from local storage. ${e.message}  Stripping")
                cacheKeys.remove(key)
                PlatformStorage.remove(key)
                persist()
                return null
            }
        }

        override fun set(key: String, value: String) {
            val d = compressForLocalStorage(value)
            try {
                cacheKeys.remove(key)
                if (artificalSizeCap != -1 && cacheKeys.values.sumOf { it.size } > artificalSizeCap)
                    throw Exception("artificalSizeCap exceeded (${cacheKeys.values.sumOf { it.size }} / $artificalSizeCap)")
                cacheKeys[key] = Info(clockMillis(), d.length)
                persist()
                PlatformStorage.set(key, d)
            } catch (e: Exception) {
                log.log("Got error ", e, ", clearing out space")
                // gotta nuke some keys
                var toNuke = d.length
                cacheKeys.entries.asSequence()
                    .sortedBy { it.value.lastUse }
                    .takeWhile {
                        val before = toNuke
                        toNuke -= it.value.size
                        before >= 0
                    }
                    .forEach {
                        log.log("Removing ${it.key} to reclaim ${it.value.size}")
                        PlatformStorage.remove(it.key)
                        cacheKeys.remove(it.key)
                    }
                try {
                    log.log("New cache size: (${cacheKeys.values.sumOf { it.size }} / $artificalSizeCap)")
                    cacheKeys[key] = Info(clockMillis(), d.length)
                    persist()
                    PlatformStorage.set(key, d)
                } catch (e: Exception) {
                    log.error(e)
                    // give up, but don't mess up the outside.  a cache is just a cache after all
                }
            }
        }

        override fun remove(key: String) {
            PlatformStorage.remove(key)
            cacheKeys.remove(key)
            persist()
        }

        override fun clear() {
            cacheKeys.keys.forEach {
                PlatformStorage.remove(it)
            }
            cacheKeys.clear()
            persist()
        }
    }

    class Test : ExternalStorage {
        val map = HashMap<String, String>()
        override fun get(key: String): String? = map.get(key)
        override fun set(key: String, value: String) = map.set(key, value)
        override fun remove(key: String) {
            map.remove(key)
        }

        override fun clear() {
            map.clear()
        }
    }
}

fun ExternalStorage.sub(prefix: String) = object : ExternalStorage {
    override fun get(key: String): String? = this@sub.get("$prefix.$key")
    override fun set(key: String, value: String) = this@sub.set("$prefix.$key", value)
    override fun remove(key: String) = this@sub.remove("$prefix.$key")
    override fun clear() = this@sub.clear()
}