package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.IncludeTopBar
import com.picme.Resources
import com.picme.UnauthScreen
import com.picme.components.*
import com.picme.fadedForeground
import com.picme.sdk2.SafeIds
import com.picme.session

@Routable("collection-unverified")
object MobileUnverifiedUserLanding : Screen, UnauthScreen, IncludeTopBar {
    override val title = Constant("")
    override fun ViewWriter.render() {
        stack {
            launch {
                screenNavigator.navigate(CollectionLanding)
            }
        }
//        stack {
//            val hideOnStartFlag = Property(false)
//            reactiveScope {
//                val collections = session()?.collection2?.listCollectionsLive()?.all()
//                if (collections?.isNotEmpty() == true) {
//                    navigator.navigate(CollectionDetail(SafeIds.encode(collections.first().collection.collectionId.raw)))
//                } else {
//                    hideOnStartFlag.set(true)
//                }
//            }
//
//            atTopCenter - col {
//                ::exists { hideOnStartFlag() }
//
//                image {
//                    source = Resources.photographer
//                }
//                centered - fadedForeground - text("To get started, create a collection")
//                space()
//                buttonTheme - important - button {
//                    centered - row {
//                        icon { source = PIcon.add }
//                        centered - h4("Create Collection")
//                        onClick { navigator.dialog.navigate(CreateCollection) }
//                    }
//                }
//                space()
//                space()
//                loginOrSignupButton()
//            } in sizeConstraints(width = 22.rem)
//        }
    }
}
