package com.picme

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


val Color.Companion.picmeBlue get() = fromHexString("#0014CC")

val Color.Companion.separatorColor get() = fromHexString("#E1E1E1")
val Color.Companion.picmeAuthGrey get() = fromHexString("#F6F6FB")
val Color.Companion.picmePink get() = fromHexString("#fa00ff")
val Color.Companion.picmePurple get() = fromHexString("#8E0DE8")
val Color.Companion.picmeToastBg get() = fromHexString("#1e1e24")
val Color.Companion.picmeToastGreen get() = fromHexString("#61d345")
val Color.Companion.picmeDanger get() = fromHex(0xFFB00020.toInt())

val Color.Companion.menuIconBg get() = fromHexString("#F4EBFF")

val Color.Companion.picmeButtonSecondary get() = fromHexString("#ececed")
val Color.Companion.picmeFaded get() = fromHexString("#41414e")

val Color.Companion.darkPurple get() = fromHexString("#6941C6")

val Color.Companion.bluePurpleGradient
    get() = LinearGradient(
        stops = listOf(
            GradientStop(0f, fromHexString("#0014cb")),
            GradientStop(1f, picmePink),
        ),
        angle = Angle.eighthTurn,
        screenStatic = false
    )
val Color.Companion.whitePurpleGradient
    get() = LinearGradient(
        stops = listOf(
            GradientStop(0f, Color.white),
            GradientStop(0.2f, Color.white),
            GradientStop(1f, fromHexString("#ede5ff")),
        ),
        angle = Angle.eighthTurn,
        screenStatic = false
    )

val appMaterialTheme: Theme = Theme.material(
    id = "picme",
    primary = Color.fromHexString("#0014CC"),
    title = FontAndStyle(font = Resources.inter, bold = true),
    body = FontAndStyle(font = Resources.inter),
    elevation = 0.rem,
    outlineWidth = 0.dp,
    cornerRadii = CornerRadii.Constant(0.5.rem),
    background = Color.white,
    spacing = 0.75.rem,
).customize(
    newId = "picme",
    iconOverride = Color.darkPurple,
    derivations = mapOf(
        CardSemantic to {
            it.copy(id = "card", background = Color.white, outlineWidth = 1.dp, outline = Color.gray(0.9f)).withBack
        },
        DialogSemantic to {
            it.copy(
                id = "dlg",
                elevation = 0.25.rem,
                revert = true,
            ).withBack
        },
//    dialog = { copy(background = Color.gray) },
        BarSemantic to {
            it.copy(
                id = "bar",
                background = Color.white,
                outlineWidth = 0.dp,
                cornerRadii = CornerRadii.ForceConstant(0.dp)
            ).withBack
        },
//        Sep to {
//            it.copy()
//        },
        ButtonSemantic to {
            it.copy(
                id = "button",
                cornerRadii = CornerRadii.ForceConstant(100.rem),
                background = Color.picmeButtonSecondary.closestColor(),
                spacing = it.spacing * 0.75,
                outlineWidth = 0.dp,
            ).withBack
        },
        UnselectedSemantic to { it.withoutBack },
        SelectedSemantic to {
            it.copy(
                id = "sel",
                background = Color.bluePurpleGradient.applyAlpha(0.1f),
                hover = { this.copy(background = Color.bluePurpleGradient.applyAlpha(0.25f)) },
            ).withBack
        },
        FocusSemantic to {
            it.copy(
                id = "focus",
                outline = if (it.background.closestColor() channelDifferenceSum Color.picmePurple >= 1f) Color.picmePurple else Color.picmeToastGreen,
                outlineWidth = 1.dp
            ).withBack
        },
        FieldSemantic to {
            it.copy(
                id = "field",
                cornerRadii = CornerRadii.ForceConstant(0.4.rem),
                spacing = 0.5.rem,
                outlineWidth = 1.dp,
                font = it.font.copy(size = 20.dp)
            ).withBack
        },
        ImportantSemantic to {
            it.copy(
                id = "important",
                foreground = Color.white,
                background = Color.bluePurpleGradient,
                iconOverride = Color.white,
                outlineWidth = 0.dp,
            ).withBack
        },
        DangerSemantic to {
            it.copy(
                id = "danger",
                foreground = Color.picmeDanger,
                outlineWidth = 0.dp
            ).withBack
        },
        H1Semantic to {
            it.copy(id = "h1", font = it.font.copy(weight = 700)).withoutBack
        },
        H3Semantic to {
            it.copy(id = "h3", font = it.font.copy(weight = 700, size = 24.dp)).withoutBack
        },
        H4Semantic to {
            it.copy(id = "h4", font = it.font.copy(weight = 600, size = 20.dp)).withoutBack
        },
        H5Semantic to {
            it.copy(id = "h5", font = it.font.copy(weight = 600, size = 1.25.rem)).withoutBack
        },
        H6Semantic to {
            it.copy(id = "h6", font = it.font.copy(weight = 600, size = 1.1.rem)).withoutBack
        },
    ),
)

object ImportantTextSemantic : Semantic {
    override val key: String = "imt"
    override fun default(theme: Theme): ThemeAndBack = theme.copy(
        id = key,
        foreground = theme[ImportantSemantic].theme.background
    ).withoutBack
}

data object FatCardSemantic : Semantic {
    override val key: String get() = "fat"
    override fun default(theme: Theme): ThemeAndBack {
        return theme[CardSemantic].theme.copy(
            id = key,
            revert = true,
            spacing = 1.5.rem,
            cornerRadii = CornerRadii.ForceConstant(1.25.rem),
        ).withBack
    }
}


val ViewWriter.fadedForeground
    get() = ThemeDerivation {
        it.copy(foreground = Color.picmeFaded).withoutBack
    }
val ViewWriter.dangerForeground
    get() = ThemeDerivation {
        it.copy(
            foreground = Color.picmeDanger,
            outline = Color.picmeDanger,
        ).withoutBack
    }
val ViewWriter.grayBack
    get() = ThemeDerivation {
        it.copy(
            background = Color.picmeAuthGrey
        ).withBack
    }
val ViewWriter.importantForeground
    get() = ThemeDerivation {
        it.copy(foreground = Color.bluePurpleGradient).withoutBack
    }


@ViewDsl
inline fun ViewWriter.formCol(crossinline setup: ContainingView.() -> Unit) {
    col {
        spacing = 1.5.rem
        setup()
    }
}

@ViewModifierDsl3
val ViewWriter.fieldSizedTheme: ViewWrapper
    get() = /*sizeConstraints(height = 3.rem) -*/ fieldTheme

@ViewModifierDsl3
val ViewWriter.labeledFieldCol: ViewWrapper
    get() = padded - tweakTheme { it.copy(spacing = 0.2.rem) }

@ViewModifierDsl3
val ViewWriter.separatorTheme: ViewWrapper
    get() = ThemeDerivation {
        it.copy(foreground = Color.separatorColor).withoutBack
    }.onNext

//data object IconButtonSemantic : Semantic {
//    override val key: String get() = "icon-button"
//    override fun default(theme: Theme): ThemeAndBack {
//        return theme[ButtonSemantic].theme.copy(
//            id = key,
//            iconOverride = Color.darkPurple,
//            foreground = Color.darkPurple,
//            background = Color.transparent,
//        ).withBack
//    }
//}
//
//data object IconButtonSelected : Semantic {
//    override val key: String get() = "icon-button-selected"
//    override fun default(theme: Theme): ThemeAndBack {
//        return theme[IconButtonSemantic].theme[SelectedSemantic].theme.copy(
//            background = Color.menuIconBg
//        ).withBack
//    }
//}


@ViewModifierDsl3
val ViewWriter.iconButtonTheme: ViewWrapper
    get() = buttonTheme - centered - ThemeDerivation {
        it.copy(
            cornerRadii = CornerRadii.ForceConstant(4.rem),
            iconOverride = Color.darkPurple,
            foreground = Color.darkPurple,
            background = Color.transparent,
        ).withBack
    }.onNext


@ViewModifierDsl3
val ViewWriter.iconButtonThemeNotCentered: ViewWrapper
    get() = buttonTheme - ThemeDerivation {
        it.copy(
            cornerRadii = CornerRadii.ForceConstant(4.rem),
            iconOverride = Color.darkPurple,
            foreground = Color.darkPurple,
            background = Color.transparent,
        ).withBack
    }.onNext


@ViewModifierDsl3
val ViewWriter.outlinedButton: ViewWrapper
    get() = iconButtonTheme - ThemeDerivation {
        it.copy(outlineWidth = 1.dp, outline = Color.darkPurple, foreground = Color.darkPurple).withoutBack
    }.onNext

@ViewModifierDsl3
val ViewWriter.dismissBackgroundTheme: ViewWrapper
    get() = ThemeDerivation {
        it.copy(background = Color.black).withoutBack
    }.onNext
