package com.picme.views.share

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.actuals.deviceCanShareFiles
import com.picme.actuals.toBlob
import com.picme.components.actionButtonNoText
import com.picme.components.popoverItemTheme
import com.picme.components.popoverWrapper
import com.picme.sdk2.GenerateQr
import com.picme.sdk2.QrType
import com.picme.views.frontendUrl
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi


@OptIn(ExperimentalEncodingApi::class)
fun ViewWriter.qrDisplay(qrCodeInfo: Readable<ViewInviteInfo>, additionalContent: ContainingView.() -> Unit) =
    scrolls - padded - col {
        val enabled = shared { !qrCodeInfo().inviteCode.temporarilyDisabled }
        spacing = 5.dp
        centered - card - col {
            spacing = 1.rem
            centered - sizeConstraints(width = 18.rem) - stack {

                val pngQrCode = shared {
                    GenerateQr.getQrCode(
                        hostName = frontendUrl(),
                        path = qrCodeInfo().inviteCode.sharePath(),
                        QrType.Png
                    ).await()
                }

                val fileBlob = shared {
                    Base64.decode(pngQrCode().base64Encoded)
                        .toBlob(pngQrCode().contentType.raw)
                }


                atTopStart - iconButtonTheme - menuButton {
                    ::enabled { enabled() }
                    requireClick = true
                    preferredDirection = PopoverPreferredDirection.belowRight
                    centered - sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - icon {
                        source = PIcon.download
                    }
                    opensMenu {
                        popoverWrapper {
                            col {
                                spacing = 5.dp
                                bold - text("File Format")
                                popoverItemTheme - button {
                                    text(".png")
                                    onClick {
                                        ExternalServices.download(
                                            name = "${currentCollection()?.name ?: "qr"}_${qrCodeInfo().inviteCode.name}${pngQrCode().fileExtension}".replace(
                                                " ",
                                                "_"
                                            ).validDownloadableName(),
                                            blob = fileBlob(),
                                            preferredDestination = DownloadLocation.Pictures
                                        )
                                        val message = when (Platform.current) {
                                            Platform.Web -> "QR code downloaded"
                                            Platform.iOS -> "QR code saved to Camera Roll"
                                            else -> "QR code downloaded"
                                        }
                                        showToast(message)
                                        closePopovers()
                                    }
                                }
                                popoverItemTheme - button {
                                    text(".svg")
                                    onClick {
                                        val newQrCode = GenerateQr.getQrCode(
                                            hostName = frontendUrl(),
                                            path = qrCodeInfo().inviteCode.sharePath(),
                                            QrType.Svg
                                        ).await()

                                        ExternalServices.download(
                                            name =
                                            "${currentCollection()?.name ?: "qr"}_${qrCodeInfo().inviteCode.name}.svg".replace(
                                                " ",
                                                "_"
                                            ).validDownloadableName(),
                                            blob = Base64.decode(newQrCode.base64Encoded)
                                                .toBlob(newQrCode.contentType.raw),
                                        )
                                        showToast("QR code downloaded")
                                        closePopovers()
                                    }
                                }
                            }
                        }
                    }
                }

                atTopEnd - actionButtonNoText(
                    displayIcon = PIcon.share,
                    exists = shared { deviceCanShareFiles() },
                    enabled = enabled
                ) { ExternalServices.share(listOf(qrCodeInfo().inviteCode.name to fileBlob())) }

                centered - sizeConstraints(width = 12.rem, height = 12.rem) - centered - image {
                    exists = false
                    ::exists { pngQrCode().base64Encoded; true }
                    ::source {
                        ImageRaw(fileBlob())
                    }
                }
                centered - sizeConstraints(width = 12.rem, height = 12.rem) - centered - ThemeDerivation {
                    it.copy(background = Color.picmeAuthGrey).withBack
                }.onNext - stack {
                    centered - activityIndicator()
                    exists = true
                    ::exists { pngQrCode().base64Encoded; false }
                }
                centered - sizeConstraints(width = 12.rem, height = 12.rem) - stack {
                    dynamicTheme {
                        if (!enabled()) {
                            ThemeDerivation {
                                it.copy(background = Color.white.applyAlpha(0.8f), outlineWidth = 0.dp).withBack
                            }
                        } else null

                    }
                }
            }

            subtext {
                align = Align.Center
                wraps = true
                wordBreak = WordBreak.BreakAll
                ::content { qrCodeInfo().link.removePrefix("https://") }
            }

            centered - row {
                centered - actionButtonNoText(PIcon.eye, enabled) { ExternalServices.openTab(qrCodeInfo().link) }
                centered - actionButtonNoText(PIcon.copy, enabled) {
                    ExternalServices.setClipboardText(qrCodeInfo().link)
                    showToast("Url copied to clipboard")
                }
                centered - actionButtonNoText(PIcon.share, enabled) {
                    ExternalServices.share(title = qrCodeInfo().permission.name, url = qrCodeInfo().link)
                }
            }
            centered - fadedForeground.onNext - text {
                ::exists { qrCodeInfo().permission != InviteType.Referral }
                ::content { "Permissions: ${qrCodeInfo().permission.readable()}" }
            }
            space()
        }
        additionalContent()
    }