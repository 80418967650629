package com.picme.views

import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.blueLink
import com.picme.components.picmeIconDisplay
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.InviteCode
import com.picme.sdk2.generated.InviteCodeId
import com.picme.sdk2.generated.collection2.CollectionSharingAuthorizationCode
import com.picme.sdk2.generated.collection2.GetCollectionResponse2
import com.picme.sdk2.toSafeDecoded
import com.picme.sdk2.toSafeEncoded
import com.picme.sdk2.unauthApi
import kotlinx.serialization.Serializable

@Routable("in/{codeId}")
class QrAccept(val codeId: String) : Screen, NoTopBar, UnauthScreen {
    override val title get() = Constant("")
    override fun ViewWriter.render() {
        stack {
            reactiveScope {
                val qr = unauthApi().collectionHandler2.getInviteCode(InviteCodeId(codeId.toSafeDecoded())).inviteCode

                if (qr.temporarilyDisabled) {
                    screenNavigator.reset(PagePaused)
                    return@reactiveScope
                }

                val collectionId = qr.clientInfo()?.collectionId?.let(::CollectionId)

                val collection = collectionId?.let { unauthApi().collectionHandler2.getCollection(it) }
                collection?.getCoverPhotoUri?.imageIfExists()

                if (collection == null && qr.clientInfo()?.type != InviteType.Referral) {
                    screenNavigator.reset(PagePaused)
                    return@reactiveScope
                }

                acceptingInvite set AcceptInvite(qrCode = qr, collection = collectionId)

                val targetScreen: Screen = when (qr.clientInfo()?.type) {
                    InviteType.RequestUploads -> RequestUploadsAccept(collection!!)
                    InviteType.ShareColl -> ShareAccept(collection!!)
                    InviteType.Referral -> ReferralAccept
                    null -> PagePaused
                }

                screenNavigator.reset(targetScreen)
            }

            fullScreenLoading(Constant(true))
        }
    }
}


object PagePaused : Screen, NoTopBar, UnauthScreen {
    override fun ViewWriter.render() {
        qrAcceptScreen {
            col {
                centered - bold - h2("Page Paused")
                centered - text {
                    content = "The page you are trying to\n access has been paused."; align = Align.Center
                }
                space()
                centered - captureExperiencesIcon()
                space()
                space()
            }
        }
    }
}

object ReferralAccept : Screen, NoTopBar, UnauthScreen {
    override fun ViewWriter.render() {
        qrAcceptScreen {
            col {
                centered - bold - h2("PicMe Invitation")
                centered - text {
                    content = "You've been invited to sign up\n for a PicMe account"; align = Align.Center
                }
                space()
                centered - captureExperiencesIcon()
                space()
                space()

                buttonTheme - important - button {
                    centered - bold - h4 {
                        content = "Get Started"
                        align = Align.Center
                    }
                    onClick {
                        if (session()?.isVerifiedAccount() == true) { // Don't accept invite if already a user
                            acceptingInvite set null
                        }
                        screenNavigator.navigate(LoginOrSignUp())
                    }
                }

                skipForNow()
            }
        }
    }
}

class ShareAccept(val collection: GetCollectionResponse2) : Screen, NoTopBar, UnauthScreen {
    override fun ViewWriter.render() {
        qrAcceptScreen() {
            col {
                centered - sizeConstraints(
                    width = 2.5.rem,
                    height = 2.5.rem
                ) - padded - ThemeDerivation { it.withoutBack }.onNext - button {
                    spacing = 0.rem
                    picmeIconDisplay()
                    onClick { ExternalServices.openTab(frontendUrl()) }
                }
                space {}
                centered - bold - h2("Guest Invitation")
                centered - text {
                    content = "You've been invited to a PicMe Collection."; align = Align.Center
                }

                collPlaceholder(shared { collection.getCoverPhotoUri.imageIfExists() })

                centered - h4 {
                    ::content { collection.collection.name }
                    align = Align.Center
                }

                space()
                buttonTheme - important - button {
                    centered - bold - h4 {
                        content = "Accept Invite"
                        align = Align.Center
                    }
                    onClick {
                        if (session() == null) {
                            screenNavigator.navigate(LoginOrSignUp(true))
                        } else {
                            acceptQrCode()
                        }
                    }
                }
                skipForNow()
            }
        }
    }
}

class RequestUploadsAccept(val collection: GetCollectionResponse2) : Screen, NoTopBar, UnauthScreen {
    override fun ViewWriter.render() {
        qrAcceptScreen(
            bottom = {
                col {
                    centered - blueLink - button {
                        ::exists {
                            (session()?.isVerifiedAccount() != true)
                        }
                        h6("Log in / Sign up")
                        onClick {
                            acceptingInvite.value = null
                            emailOrPhone set ""

                            dialogScreenNavigator.clear()
                            mainScreenNavigator.navigate(LoginOrSignUp(true))
                        }
                    }
                    space {}
                }
            }
        ) {
            col {
                centered - sizeConstraints(
                    width = 2.5.rem,
                    height = 2.5.rem
                ) - padded - ThemeDerivation { it.withoutBack }.onNext - button {
                    spacing = 0.rem
                    picmeIconDisplay()
                    onClick { ExternalServices.openTab(frontendUrl()) }
                }
                space {}
                collPlaceholder(shared { collection.getCoverPhotoUri.imageIfExists() })
                centered - h4 {
                    ::content { collection.collection.name }
                    align = Align.Center
                }
                fadedForeground.onNext - centered - text {
                    ::content { collection.collection.message }
                    align = Align.Center
                }

                space()
                space()
                uploadPhotosButton {
                    val encoded = collection.collection.collectionId.raw.toSafeEncoded()
                    val isAuthenticated = session.value?.isVerifiedAccount() == true
                    val screen =
                        if (isAuthenticated) CollectionDetail(encoded)
                        else PartialAuthUploadView(encoded)

                    uploadExternalFilesFirstTime(
                        collId = collection.collection.collectionId,
                        onFinish = {
                            screenNavigator.navigate(screen)
                        }
                    )
                }
            }
        }
    }
}


fun ViewWriter.qrAcceptScreen(
    bottom: ContainingView.() -> Unit = {},
    setup: ContainingView.() -> Unit
) {
    themedBg - stack {
        gravity(Align.Center, Align.Start) - sizeConstraints(width = 24.rem) - padded - col {
            space {}
            space { ::exists { WindowInfo().height > 800.dp } }
            col { centered - sizeConstraints(width = 22.rem) - setup() }
            space()
            bottom()
        }
    }
}

fun ViewWriter.collPlaceholder(cover: Readable<ImageRemote?>) {
    centered - sizeConstraints(width = 12.rem, height = 12.rem) - image {
        exists = false
        ::exists { cover() == null }
        source = Resources.picmePaige
    }
    centered - ThemeDerivation {
        it.copy(cornerRadii = CornerRadii.ForceConstant(2.5.rem)).withoutBack
    }.onNext - sizeConstraints(width = 12.rem, height = 12.rem) - image {
        exists = true
        ::exists { cover() != null }
        scaleType = ImageScaleType.Crop
        ::source { cover() }
    }
}

fun ViewWriter.skipForNow() = centered - blueLink - button {
    text("Skip for Now")
    onClick {
        if (session() == null) {
            ExternalServices.openTab("https://picme.com")
        } else {
            acceptingInvite set null
            navigateToCollOrLanding()
        }
    }
}


fun ViewWriter.uploadPhotosButton(onClick: suspend () -> Unit) = buttonTheme - important - button {
    centered - row {
        centered - ThemeDerivation { it.copy(iconOverride = Color.white).withoutBack }.onNext - icon {
            source = PIcon.upload
        }
        centered - bold - h4 {
            content = "Upload Photo"
            align = Align.Center
        }
    }
    onClick(onClick)
}


fun ViewWriter.captureExperiencesIcon() {
    centered - picmeIconDisplay(64.dp)
    ThemeDerivation { it.copy(foreground = Color.picmePurple).withoutBack }.onNext - HeaderSemantic.onNext - text {
        align = Align.Center
        content = "capture experiences"
    }
}

@Serializable
data class LegacyAccept(
    val sharingAuthCode: CollectionSharingAuthorizationCode? = null
)

@Serializable
data class AcceptInvite(
    val qrCode: InviteCode? = null,
    val legacy: LegacyAccept? = null,
    val collection: CollectionId?,
    val alreadyAccepted: Boolean = false
)

val acceptingInvite = Property<AcceptInvite?>(null)

//ThemeDerivation {
//                        it.copy(
//                            background = it.background.applyAlpha(0f),
//                            outlineWidth = 1.dp,
//                            outline = Color.picmePurple.applyAlpha(0.7f)
//                        ).withBack
//                    }.onNext - col {
//                        subtext {
//                            content = "For a better experience, download our free app."
//                            align = Align.Center
//                        }
//                        row {
//                            sizeConstraints(width = 8.rem) - image {
//                                source = Resources.googleBadge
//                            }
//                            expanding - space()
//                            sizeConstraints(width = 8.rem) - image {
//                                source = Resources.appleBadge
//                            }
//                        }
//                    }