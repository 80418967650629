package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.actuals.deviceCanShareFiles
import com.picme.components.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.generated.collection2.GetUploadResponse2
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.PatchUploadBody
import com.picme.sdk2.generated.collection2.UploadId


enum class CaptionStatus { Closed, ManualOpen, DefaultOpen }

fun Stack.imageViewPagerOverlay(
    imagesWithAds: Readable<MutableList<ImageDisplayInfo>>,
    curr: Readable<RecyclableInfo>,
    collection: Readable<PCollection>
) {
    val currImage = shared { imagesWithAds().first { it.id == curr().id } }
    val showCaption = Property(CaptionStatus.Closed)
    val caption = Property("")

    val isAd = shared { curr().id == adUploadId }

    val details = shared {
        if (currImage().id == adUploadId) null
        else session.awaitNotNull().collection2.getUploadLive(
            collectionId = currentCollection()?.collectionId!!,
            uploadId = currImage().id.let(::UploadId)
        )()
    }

    suspend fun deleteImage() {
        val isLast =
            imagesWithAds().indexOfFirst { it.id == curr().id } == imagesWithAds().size - 1
        deleteImages(
            collection().images().all().filter { it.uploadId.raw == currImage().id }
        )
        if (isLast) dialogScreenNavigator.dismiss()
    }

    val showBottomActions = shared { isSmallScreen() && !isAd() }

    imageViewTop {
        reactiveScope {
            caption set (details()?.upload?.caption?.raw ?: "")
            showCaption set (details()?.let {
                if (it.upload.caption.raw.isBlank()) CaptionStatus.Closed else CaptionStatus.DefaultOpen
            } ?: CaptionStatus.Closed)
        }
        centered - actionButtonNoText(PIcon.chevronleft) { dialogScreenNavigator.dismiss() }
        space {} in weight(1f)

        gravity(Align.End, Align.Center) - h4 {
            ::exists { isAd() }
            content = "Advertisement"
        }
        padded - row {
            onlyWhen { !showBottomActions() && !isAd() } - imageActions(
                false,
                currImage,
                details,
                showCaption,
                ::deleteImage
            ) {
                if (showCaption() == CaptionStatus.Closed) {
                    showCaption set CaptionStatus.ManualOpen
                } else showCaption set CaptionStatus.Closed
            }
            col {
                spacing = 0.dp
                ::exists { !isAd() }
                atEnd - row {
                    spacing = 4.dp
                    text("Uploaded")
                    bold - text { ::content { formatIsoDate(details()?.upload?.uploadTime.toString()) } }
                }
                atEnd - row {
                    spacing = 4.dp
                    text("by")
                    bold - text { ::content { details()?.uploader?.uploaderName?.ifBlank { "me" } ?: "" } }
                }
            }
        }
    }


    val captionTextArea: TextArea

    val alwaysOpen = shared { !isSmallScreen() }
    atBottom - unpadded - stack {
        spacing = 0.dp
        bar - unpadded - col {
            spacing = 0.dp
            atTop - unpadded - separatorTheme - separator() {
                ::exists { showBottomActions() }
            }
            row {
                ::exists { showCaption() != CaptionStatus.Closed || alwaysOpen() }
                ThemeDerivation {
                    it.copy(font = it.font.copy(size = 1.2.rem)).withoutBack
                }.onNext - expanding - padded - textArea {
                    content bind caption
                    hint = "Enter a caption..."
                    captionTextArea = this
                }
                sizeConstraints(minWidth = 3.rem, minHeight = 3.rem) - stack {
                    centered - actionButtonNoText(
                        PIcon.check,
                        exists = shared { details()?.upload?.caption?.raw != caption() }) {
                        session()?.collection2?.patchUpload(
                            collectionId = currentCollection()!!.collectionId,
                            uploadId = currImage().id.let(::UploadId),
                            body = PatchUploadBody(caption = Retainable(caption()))
                        )
                    }
                }
            }

            separatorTheme - separator {
                ::exists { showCaption() != CaptionStatus.Closed && !alwaysOpen() }
                spacing = 0.dp
            }

            onlyWhen { showBottomActions() } - padded - imageActions(
                true,
                currImage,
                details,
                showCaption,
                ::deleteImage
            ) {
                if (showCaption() == CaptionStatus.Closed) {
                    showCaption set CaptionStatus.ManualOpen
                } else showCaption set CaptionStatus.Closed

                captionTextArea.requestFocus()
            }
        }
    }
}

fun ViewWriter.imageActions(
    atBottom: Boolean,
    currImage: Readable<ImageDisplayInfo>,
    details: Readable<GetUploadResponse2?>,
    showCaption: Readable<CaptionStatus>,
    confirmDelete: suspend () -> Unit,
    onClickActionButton: suspend () -> Unit
) {
    row {
        centered - actionButtonNoText(PIcon.trash) {
            showConfirmDialog(
                title = "Confirm Delete",
                content = "This item will be deleted from the collection.",
                confirmLabel = "Delete",
                onConfirm = confirmDelete
            )
        }
        space(multiplier = 2.0) { exists = !atBottom }

        expanding - stack {
            centered - actionButtonNoText(PIcon.download) {
                val imageToDownload = currImage()
                openProgressModal(
                    title = "Downloading file",
                    execute = {
                        launchGlobal {
                            image = imageToDownload.thumbnail
                            try {
                                individualItemProgress = 0.25f
                                val filename = imageToDownload.id.validDownloadableName()
                                val url = getDetailsUri(imageToDownload.id.let(::UploadId))
                                ExternalServices.download(filename, url, DownloadLocation.Pictures) {
                                    individualItemProgress = it
                                }
                                individualItemProgress = 0.5f
                                delay(1000)
                                individualItemProgress = 0.75f
                                delay(1000)

                            } catch (e: Exception) {
                                e.printStackTrace()
                                showToast("Download failed. Check PicMe's Camera Roll access in Settings.")
                            }
                        }

                    }, onComplete = {
                        showToast(
                            when (Platform.current) {
                                Platform.iOS -> "File saved to Camera Roll"
                                else -> "File downloaded"
                            }
                        )
                    }
                )
            }
        }
        space(multiplier = 2.0) { exists = !atBottom && deviceCanShareFiles() }
        expanding - stack {
            exists = deviceCanShareFiles()
            centered - actionButtonNoText(PIcon.share) {
                val copy = getBlob(details()?.getDetailsUri ?: "")
                ExternalServices.share(listOf(currImage().id.validDownloadableName() to copy))
            }
        }
//        space(multiplier = 2.0) { exists = !atBottom }
        expanding - stack {
            exists = atBottom
            centered - actionButtonNoText(
                displayIcon = shared {
                    if (showCaption() == CaptionStatus.Closed) PIcon.caption
                    else PIcon.captionon
                },
            ) { onClickActionButton() }
        }

        space(multiplier = 2.0) { exists = !atBottom }

        centered - iconButtonTheme - menuButton {
            ::exists { currentCollection()?.let { ownsPCollection(it) } ?: false }
            icon(PIcon.more, "More Actions")
            preferredDirection =
                if (atBottom) PopoverPreferredDirection.aboveLeft else PopoverPreferredDirection.belowLeft
            requireClick = true
            opensMenu {
                popoverWrapper {
                    col {
                        popoverItem {
                            icon.source = PIcon.copy
                            text.content = "Copy to another collection"
                            button.onClick {
                                openCopyMenu(listOf(UploadId(currImage().id)))
                                closePopovers()
                            }
                        }
                        popoverItem {
                            icon.source = PIcon.image
                            text.content = "Use as Collection Image"
                            button.onClick {
                                val uri = details()?.getDetailsUri ?: return@onClick
                                val copy = getBlob(uri)
                                val coll = currentCollection() ?: return@onClick
                                session()!!.collection2.putCollectionCoverPhoto(
                                    collectionId = coll.collectionId,
                                    body = RequestBodyBlob(content = copy),
                                    tempUri = uri,
                                    onProgress = {}
                                )
                                closePopovers()
                            }
                        }
                    }
                }
            }
        }

        space(multiplier = 2.0) { exists = !atBottom }
    }
}

suspend fun getBlob(url: String): Blob {
//    https://stackoverflow.com/questions/65191708/chrome-browser-and-cors-issue-when-caching-present-in-request-headers
    return com.lightningkite.kiteui.fetch(
        url, method = HttpMethod.GET,
        httpHeaders().apply {
            append("mode", "cors")
            append("cache", "no-cache")
            append("Cache-Control", "no-cache")
        },
    ).blob()
}