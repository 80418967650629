package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.session
import com.picme.sdk2.caching.CollectionHandler2ApiCacheableLive2


@Routable("password-and-security")
object PasswordAndSecurity : PicmeDialog() {
    override val title = Constant("Password and Security")
    override fun ViewWriter.content() = formCol {
        col {
            spacing = 0.2.rem
            h4("Password")
            secondaryButton {
                label = "Change Password"
                button.onClick {
                    dialogScreenNavigator.navigate(ResetPasswordDialog)
                }
            }
        }
    }
}


object ResetPasswordDialog : PicmeDialog() {
    override val title = Constant("Reset Password")
    val oldPassword = Property("")
    val newPassword = Property("")
    val passwordError: Property<String?> = Property(null)

    override fun ViewWriter.content() {
        col {
            fadedForeground.onNext - subtext("To change your password, enter your current password and the new password for your account.")

            styledPassword {
                content bind oldPassword
                label = "Old Password"
                hint = "Old Password"
            }
            styledPassword {
                content bind newPassword
                label = "New Password"
                hint = "New Password"
                error bind passwordError
            }

            actionOrCancelSection(
                onCancel = {
                    dialogScreenNavigator.clear()
                },
                actionButton = {
                    importantButton("Submit") {
                        if (oldPassword().length < 8 || newPassword().length < 8) {
                            passwordError set "Password must be at least 8 characters long"
                            return@importantButton
                        }
                        try {
                            session.awaitNotNull().authentication.changeUserPassword(oldPassword(), newPassword())
                            dialogScreenNavigator.clear()
                            showToast("Password successfully changed.")
                        } catch (e: Exception) {
                            showToast("Unable to change your password. You can double check your old password and try again.")
                        }
                    }
                }
            )
        }
    }
}

object DeleteAccount : PicmeDialog() {
    override val title = Constant("Delete Account")
    override fun ViewWriter.content() {
        val confirmDeleteAccount = Property("")
        val errorText = Property<String?>(null)
        col {
            text("Are you sure you want to delete your account?")
            text("To confirm, please type \"delete my account\" below.")
            reactiveScope {
                if (confirmDeleteAccount() == "delete my account") {
                    errorText set null
                }
            }
            styledTextField {
                label = "Confirmation"
                hint = "delete my account"
                content bind confirmDeleteAccount
                error bind errorText
            }

            danger - text("This action is not recoverable! Your account, and all associated photos, collections & referrals will be deleted!")
            actionOrCancelSection(
                onCancel = {
                    dialogScreenNavigator.clear()
                },
                actionButton = {
                    buttonTheme - tweakTheme {
                        it.copy(background = Color.picmeDanger, foreground = Color.white)
                    } - button {
                        centered - h6("Delete Account")
                        onClick {
                            if (confirmDeleteAccount() == "delete my account") {
                                dialogScreenNavigator.clear()
                                launchGlobal {
                                    session()?.authentication?.deleteUser()
                                    showToast("Your account has been deleted")
                                }
                                logout()
                                showToast("Your account has been deleted.")
                            } else {
                                errorText set "Confirmation text is incorrect"
                            }
                        }
                    }
                }
            )
        }
    }
}