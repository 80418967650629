package com.picme.layout

import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.closePopovers
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.picme.PIcon
import com.picme.components.CreateCollection
import com.picme.components.actionButtonNoText
import com.picme.isSmallScreen
import com.picme.separatorTheme
import com.picme.views.CollectionDetail
import com.picme.views.collectionList

fun ViewWriter.sidePanel() {
    padded - col {
        spacing = 0.dp
        ::exists { !isSmallScreen() && screenNavigator.currentScreen() is CollectionDetail }
        val showSearch = Property(false)
        padded - row {
            centered - h4("Collections")
            expanding - space {}
            actionButtonNoText(
                displayIcon = PIcon.add,
                onClick = {
                    closePopovers()
                    dialogScreenNavigator.navigate(CreateCollection)
                }
            )
            actionButtonNoText(displayIcon = PIcon.search) {
                showSearch set !showSearch()
            }
        }
        expanding - sizeConstraints(width = 340.dp) - collectionList(showSearch)
    }

    separatorTheme - separator {
        ::exists { !isSmallScreen() && screenNavigator.currentScreen() is CollectionDetail }
    }
}