package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.UploadId

val trashSelectedImages = Property<List<String>?>(null)

class ViewTrash(val collectionId: CollectionId, val onBack: suspend () -> Unit) : PicmeDialog() {
    override val title = Constant("Trash")
    override val alwaysLarge = true

    override fun ViewWriter.topBar(close: suspend () -> Unit) = col {
        row {
            centered - actionButtonNoText(displayIcon = PIcon.chevronleft) { onBack() }
            centered - h3 {
                ::content { title() }
            }
            expanding - space()
            fadedForeground.onNext - centered - subtext("Items expire after 30 days")
        }
    }

    override fun ViewWriter.content() {
        expanding - unpadded - stack {
            val collection = shared { session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection }
            val trashImages = shared {
                session.awaitNotNull().collection2.listDeletedUploadsLive(collectionId).all()
            }

            centered - activityIndicator {
                reactiveScope {
                    exists = true
                    trashImages()
                    exists = false
                }
            }
            centered - fadedForeground.onNext - text {
                exists = false
                ::exists { trashImages().isEmpty() }
                content = "Trash is empty"
            }
            expanding - col {
                exists = false
                ::exists { trashImages().isNotEmpty() }

                expanding - imageRecyclerView(
                    colImages = shared { trashImages().toRecyclableInfo() },
                    selectedImages = trashSelectedImages,
                    navToImage = { img ->
                        ImageViewPager(
                            images = shared { trashImages().toImageDisplayInfo() },
                            imageViewPagerOverlay = { listOfImages, curr ->
                                imageViewPagerOverlayTrash(listOfImages, curr, collection)
                            }
                        ).apply {
                            this.imageId.value = img().id
                        }
                    },
                    setup = {
                        reactiveScope {
                            collection().trashedImages().requireIndexLoaded = lastVisibleIndex() + 50
                        }
                    }
                )
            }

        }
    }
}

fun ViewWriter.trashActions(bottom: Boolean) {
    stack {
        val collection = currentCollection
        val weAreOwner = shared { ownsPCollection(collection()) }
        val trashImages = shared {
            collection()?.collectionId?.let {
                session.awaitNotNull().collection2.listDeletedUploadsLive(it).all()
            } ?: listOf()
        }
        row {
            ::exists { trashSelectedImages() == null }
            space() { exists = !bottom }
            actionButtonNoText(PIcon.select) { trashSelectedImages set listOf() }
            expanding - space { exists = bottom }
            trashActionsMenu(bottom)
        }
        row {
            ::exists { trashSelectedImages() != null }
            imageSelectedCounter(trashSelectedImages)
            space { exists = bottom } in weight(1f)

            space() { exists = !bottom }

            actionButtonNoText(
                exists = weAreOwner,
                displayIcon = PIcon.restore,
                enabled = shared { trashSelectedImages()?.isNotEmpty() == true },
            ) {
                showConfirmDialog(
                    title = "Confirm Restore",
                    content = "Selected items will be restored to the collection.",
                    confirmLabel = "Restore",
                    onConfirm = {
                        val images = trashImages().filter { it.uploadId.raw in trashSelectedImages()!! }
                        trashSelectedImages set null
                        restoreImages(images)
                    }
                )
            }

            actionButtonNoText(
                exists = weAreOwner,
                displayIcon = PIcon.trash,
                enabled = shared { trashSelectedImages()?.isNotEmpty() == true }
            ) {
                showConfirmDialog(
                    title = "Confirm Delete",
                    content = "Selected items will be permanently deleted.",
                    confirmLabel = "Delete",
                    warning = "This action cannot be undone.",
                    onConfirm = {
                        val images = trashImages().filter { it.uploadId.raw in trashSelectedImages()!! }
                        trashSelectedImages set null
                        deleteImages(images)
                    }
                )
            }
        }
    }
}


fun ViewWriter.trashActionsMenu(bottom: Boolean) {
    centered - iconButtonTheme - menuButton {
        val collection = currentCollection
        requireClick = true
        centered - icon(PIcon.more, "More Horiz")
        preferredDirection = if (bottom) PopoverPreferredDirection.aboveLeft
        else PopoverPreferredDirection.belowLeft

        opensMenu {
            popoverWrapper(width = 20.rem) {
                col {
                    toggleGridSizePopover()

                    popoverItem {
                        icon.source = PIcon.restore
                        text.content = "Restore all items"
                        button.onClick {
                            closePopovers()
                            showConfirmDialog(
                                title = "Confirm Restore",
                                content = "All items will be restored to the collection.",
                                confirmLabel = "Restore",
                                onConfirm = {
                                    val collectionId = collection()?.collectionId ?: return@showConfirmDialog
                                    restoreImages(
                                        session.awaitNotNull().collection2.listDeletedUploadsLive(collectionId).all()
                                    )
                                }
                            )
                        }
                    }

                    popoverItem {
                        icon.source = PIcon.trash
                        text.content = "Delete all items"
                        button.onClick {
                            closePopovers()
                            showConfirmDialog(
                                title = "Confirm Delete",
                                content = "All items will be permanently deleted.",
                                confirmLabel = "Delete",
                                warning = "This action cannot be undone.",
                                onConfirm = {
                                    val collectionId = collection()?.collectionId ?: return@showConfirmDialog
                                    deleteImages(
                                        session.awaitNotNull().collection2.listDeletedUploadsLive(collectionId).all(),
                                        all = true
                                    )
                                }
                            )
                        }
                    }
                }
            }
        }
    }
}

fun ViewWriter.imageSelectedCounter(images: Property<List<String>?>) {
    stack {
        reactiveScope {
            images set null
            currentCollection()
        }
        actionButtonNoText(PIcon.close, exists = shared { images()?.size == 0 }) {
            images set null
        }
        outlinedButton - button {
            ::exists { (images()?.size ?: 0) > 0 }
            row {
                spacing = 0.rem
                centered - icon {
                    source = PIcon.close
                }
                expanding - space {}
                centered - h6 {
                    ::content { images()?.size.toString() }
                }
                expanding - space { }
            }
            onClick { images set null }
        }
    }
}


fun ViewWriter.imageViewPagerOverlayTrash(
    imagesWithAds: Readable<MutableList<ImageDisplayInfo>>,
    imageId: Readable<String>,
    collection: Readable<PCollection>
) {
    val currImage = shared { imagesWithAds().first { it.id == imageId() } }

    suspend fun deleteImage() {
        val isLast =
            imagesWithAds().indexOfFirst { it.id == imageId() } == imagesWithAds().size - 1
        deleteImages(
            collection().trashedImages().all().filter { it.uploadId.raw == currImage().id }
        )
        if (isLast) dialogScreenNavigator.dismiss()
    }

    expanding - gravity(Align.Stretch, Align.Start) - bar - row {
        iconButtonTheme - centered - button {
            icon { source = PIcon.chevronleft }
            onClick { dialogScreenNavigator.dismiss() }
        }
        onlyWhen { !isSmallScreen() } - actionRow(::deleteImage)
    }

    atBottom - onlyWhen { imageId() != adUploadId } - bar - ThemeDerivation {
        it.copy(background = Color.white, foreground = Color.black).withBack
    }.onNext - stack {
        ::exists { isSmallScreen() }
        actionRow(::deleteImage)
    }
}

fun ViewWriter.actionRow(deleteImage: suspend () -> Unit) {
    expanding - row {
        space {} in weight(1f)
        iconButtonTheme - button {
            icon { source = PIcon.trash }
            onClick {
                showConfirmDialog(
                    title = "Confirm Delete",
                    content = "This item will be permanently deleted.",
                    confirmLabel = "Delete",
                    warning = "This action cannot be undone.",
                    onConfirm = deleteImage
                )
            }
        }
    }
}
