package com.picme

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.navigatorView
import com.lightningkite.kiteui.views.l2.navigatorViewDialog
import com.lightningkite.kiteui.views.l2.overlayStack
import com.picme.components.*
import com.picme.layout.sidePanel
import com.picme.layout.toolbar
import com.picme.sdk2.Session
import com.picme.sdk2.unauthApi
import com.picme.views.*

interface UnauthScreen
interface IncludeTopBar
interface NoTopBar


private val loadingInitial = Property(true)

val requireVerifiedAccounts = Platform.current != Platform.iOS && Platform.current != Platform.Android

fun ViewWriter.app(main: ScreenNavigator, dialog: ScreenNavigator) {
    appMaterialTheme.onNext - customAppBase(main, dialog) {
        launch {
            try {
                if (sessionRefreshToken() != null && session() == null) {
                    val res = unauthApi().authenticationHandler.refreshUserAuthentication(sessionRefreshToken()!!)
                    val newSession = Session(
                        unauthApi(),
                        res.successfulAuthentication!!.authenticated,
                        res.successfulAuthentication.authenticatedUser
                    )
                    newSession.collection2.listCollectionsLive().all()
                    session set newSession
                }
            } catch (e: Exception) {
                e.printStackTrace2()
                logout()
            } finally {
                loadingInitial.set(false)
            }
        }
        CalculationContext.NeverEnds.reactiveScope {
            if (loadingInitial()) return@reactiveScope
            val s = screenNavigator.currentScreen()

            val notVerified =
                (session() == null || (requireVerifiedAccounts && session()?.isVerifiedAccount() == false))

            if (s !is UnauthScreen && notVerified) {
                screenNavigator.navigate(
                    if (requireVerifiedAccounts) LoginOrSignUp() else CollectionLanding
                )
            }
            if (s is LoginOrSignUp && session()?.isVerifiedAccount() == true) {
                navigateToCollOrLanding()
            }
        }

        reactiveScope {
            if (logoutTrigger()) {
                launchGlobal {
                    removeCaches()
                    session.value = null
                    sessionRefreshToken set null
                    currentCollection set null
                    acceptingInvite set null
                    dialogScreenNavigator.dismiss()
                    screenNavigator.reset(LoginOrSignUp())
                    logoutTrigger set false
                }
            }
        }

        fullScreenLoading(loadingInitial)

        col {
            ::exists { !loadingInitial() }
            spacing = 0.px
            expanding - row {
                spacing = 1.dp
                sidePanel()
                expanding - col {
                    spacing = 0.dp
                    toolbar()
                    expanding - navigatorView(this.screenNavigator)
                }
            }
        }
    }
}


fun ViewWriter.customAppBase(main: ScreenNavigator, dialog: ScreenNavigator, mainLayout: ContainingView.() -> Unit) {
    stack {
        spacing = 0.dp
        mainScreenNavigator = main
        dialog.let {
            dialogScreenNavigator = it
        }
        main.bindToPlatform(context)
        screenNavigator = main
        overlayStack = this
        mainLayout()
        dialog.let {
            navigatorViewDialog() in ThemeDerivation { it.withoutBack }.onNext
        }
        toast()
    }
}


@Routable("/")
class LandingScreen : Screen {
    override fun ViewWriter.render() {
        screenNavigator.replace(CollectionLanding)
    }
}

