package com.picme

import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.picme.components.RecyclableInfo
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.views.CollSubPage


object CollectionState {
    private val view = Property(CollSubPage.Main)

    val currView: Readable<CollSubPage> get() = view
    suspend fun setView(v: CollSubPage) {
        view set v
    }

    suspend fun toggleView(v: CollSubPage) {
        view set if (view() == v) CollSubPage.Main else v
    }


    val filterGuests = Property<List<CollectionUserData>>(emptyList())
    val filterDate = Property<DateOptions?>(null)

    val filtersOn: Readable<Boolean> = shared {
        filterGuests().isNotEmpty() || filterDate() != null
    }

    suspend fun clearFilters() {
        filterGuests set emptyList()
        filterDate set null
    }

    val selectItems = SelectItems()
    val selectTrash = SelectItems()
}

class SelectItems {
    private val items = Property<List<RecyclableInfo>?>(null)

    val isSelecting: Readable<Boolean> = shared { items() != null }
    val selected: Readable<List<RecyclableInfo>> = shared { items() ?: listOf() }

    suspend fun startSelecting() {
        items set listOf()
    }

    suspend fun stopSelecting() {
        items set null
    }

    suspend fun addItem(item: RecyclableInfo) {
        items set items()?.plus(item)
    }

    suspend fun removeItem(item: RecyclableInfo) {
        items set items()?.minus(item)
    }
}

enum class DateOptions(private val str: String) {
    Today("Today"),
    Yesterday("Yesterday"),
    ThisWeek("This Week"),
    Last7Days("Last 7 Days"),
    ThisMonth("This Month"),
    Last30Days("Last 30 Days"),
    Custom("Custom");

    override fun toString(): String {
        return str
    }
}
