package com.picme.views

import com.lightningkite.kiteui.RequestBodyFile
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.button
import com.lightningkite.kiteui.views.direct.scrolls
import com.lightningkite.kiteui.views.direct.space
import com.lightningkite.kiteui.views.direct.text
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PatchCollectionBody

class CollectionSettings(val urlSafeId: String, val onBack: suspend () -> Unit) : PicmeDialog() {
    override val title = Constant("Collection Settings")
    override val leftAlignIfLarge = true
    override fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = title,
        onBack = onBack,
    )

    private val collName = Property("")
    private val message = Property("")

    val collectionId = CollectionId(SafeIds.decode(urlSafeId))
    val collection = shared {
        session.awaitNotNull().collection2.getCollectionLive(collectionId)()
    }

    override fun ViewWriter.content() {
        val chosenImage = Property(ImageUploadInfo())

        scrolls - formCol {
            launch {
                val collection = collection()
                collName set collection.collection.name
                message set collection.collection.message
                chosenImage set ImageUploadInfo(original = collection().getCoverPhotoUri.imageIfExists())
            }


            formCol {
                styledTextField {
                    label = "Collection Name"
                    hint = "Name"
                    content bind collName
                }

                chooseCoverIcon(
                    chosenImage = chosenImage,
                )

                styledTextField {
                    label = "Message to Guest uploaders"
                    hint = "Message (optional)"
                    content bind message
                }

                actionOrCancelSection(
                    onCancel = onBack,
                    actionButton = {
                        importantButton("Save", enabled = shared { collName().isNotEmpty() }) {
                            chosenImage().newImage?.let {
                                session.awaitNotNull().collection2.putCollectionCoverPhoto(
                                    collectionId = collectionId,
                                    body = RequestBodyFile(it),
                                    onProgress = {}
                                )
                            }

                            session.awaitNotNull().collection2.patchCollection(
                                collectionId = collectionId,
                                body = PatchCollectionBody(
                                    name = Retainable(collName()),
                                    message = Retainable(message())
                                )
                            )
                            navigator.dismiss()
                        }
                    }
                )

                space()
                centered - compact - danger - button {
                    text { content = "Delete collection" }
                    onClick {
                        showConfirmDialog(
                            title = "Confirm Delete",
                            content = "This collection will be permanently deleted.",
                            warning = "This action cannot be undone.",
                            confirmLabel = "Delete",
                            onConfirm = {
                                session.awaitNotNull().collection2.deleteCollection(collectionId)
                                currentCollection set null
                                navigateToCollOrLanding(notThisCollection = collectionId)
                            }
                        )
                    }
                }
            }
        }
    }
}