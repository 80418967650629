package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.UploadId


val selectedImages = Property<List<String>?>(null)

val view = Property(CollSubPage.Main)
val qrInfo = Property<ViewInviteInfo?>(null)

enum class CollSubPage { Main, QrList, Settings, Guests, QrDetail, Trash }

@Routable("/collection/{urlSafeId}")
class CollectionDetail(val urlSafeId: String) : Screen {
    private val collectionId = CollectionId(SafeIds.decode(urlSafeId))
    override val title: Readable<String> = shared {
        session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection.name
    }

    override fun ViewWriter.render() {
        val collection = shared {
            session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection
        }


        suspend fun backToMain() {
            view set CollSubPage.Main
        }

        stack {
            spacing = 0.dp
            swapView {
                launch { backToMain() }

                swapping(
                    current = { view() },
                    views = {
                        when (it) {
                            CollSubPage.QrList -> {
                                with(
                                    QRCodeList(
                                        collectionId,
                                        onBack = ::backToMain,
                                        navToInvite = {
                                            qrInfo set it
                                            view set CollSubPage.QrDetail
                                        }
                                    )
                                ) { render() }
                            }

                            CollSubPage.QrDetail -> {
                                with(QRCodeDetail(shared { qrInfo.awaitNotNull() }, onBack = {
                                    view set CollSubPage.QrList
                                })) { render() }
                            }

                            CollSubPage.Settings -> {
                                with(
                                    CollectionSettings(
                                        urlSafeId = SafeIds.encode(collectionId.raw),
                                        onBack = ::backToMain
                                    )
                                ) { render() }
                            }

                            CollSubPage.Guests -> {
                                with(Guests(collectionId, onBack = ::backToMain)) { render() }
                            }

                            CollSubPage.Trash -> {
                                with(ViewTrash(collectionId, onBack = ::backToMain)) { render() }
                            }


                            CollSubPage.Main -> stack {
                                val trigger = Property(0)
                                reactiveScope {
                                    delay(8000)
                                    checkRefreshCollImages()
                                    trigger set trigger() + 1
                                }
                                spacing = 0.px
                                col {
                                    spacing = 0.px
                                    reactiveScope {
                                        currentCollection.value = collection()
                                    }
                                    val listUploadsLive =
                                        shared { session.awaitNotNull().collection2.listUploadsLive(collectionId) }

                                    expanding - stack {
                                        centered - activityIndicator {
                                            exists = true
                                            ::exists {
                                                collection().images().all()
                                                false
                                            }
                                        }
                                        centered - sizeConstraints(minWidth = 150.dp) - col {
                                            ::exists {
                                                (!listUploadsLive().pullingMore() && listUploadsLive().all().isEmpty())
                                            }
                                            spacing = 1.rem
                                            outlinedButton - button {
                                                row {
                                                    spacing = 0.rem
                                                    space { }
                                                    centered - icon {
                                                        source = PIcon.upload
                                                    }
                                                    expanding - space { }
                                                    centered - h6("Upload")
                                                    expanding - space { }
                                                }
                                                onClick { uploadExternalFiles(collectionId) }
                                            }
                                        }
                                        imageRecyclerView(
                                            colImages = shared {
                                                collection().images().all().toRecyclableInfo()
                                            },
                                            setup = {
                                                reactiveScope {
                                                    collection().images().requireIndexLoaded = lastVisibleIndex() + 50
                                                }
                                            },
                                            navToImage = { img ->
                                                ImageViewPager(
                                                    images = shared {
                                                        collection().images().all().toImageDisplayInfo()
                                                    },
                                                    imageViewPagerOverlay = { listOfImages, curr ->
                                                        imageViewPagerOverlay(listOfImages, curr, collection)
                                                    }
                                                ).apply { this.imageId.value = img().id }
                                            },
                                            selectedImages = selectedImages
                                        )

                                    }
                                }
                            }
                        }
                    }
                )
            }
            atBottom - swapView {
                spacing = 0.75.rem
                swapping(
                    current = {
                        if (!isSmallScreen()) null
                        else view()
                    },
                    views = {
                        when (it) {
                            CollSubPage.Main -> collectionNavButtons(collection, true)
                            CollSubPage.Trash -> bar - stack {
                                ::exists { isSmallScreen() }
                                atTop - separatorTheme - unpadded - separator()
                                trashActions(true)
                            }
                            else -> {}
                        }
                    }
                )
            }
        }
    }
}


fun ViewWriter.collectionNavButtons(collection: Readable<PCollection>, atBottom: Boolean) = stack {
    spacing = 0.rem
    val collectionId = shared { collection().collectionId }
    val imagePaging = shared {
        session.awaitNotNull().collection2.listUploadsLive(collectionId())
    }
    val weAreOwner = shared { ownsPCollection(collection()) }
    bar - row {
        spacing = if (atBottom) 0.75.rem else 0.rem
        ::exists { selectedImages() == null }

        space { exists = !atBottom }
        actionButtonNoText(PIcon.select) { selectedImages set listOf() }
        space { exists = !atBottom }
        expanding - stack {
            centered - actionButtonNoText(PIcon.upload) { uploadExternalFiles(collectionId()) }
        }
        space { ::exists{ !atBottom && weAreOwner() } }
        expanding - stack {
            ::exists { weAreOwner() }
            centered - actionButtonNoText(PIcon.qrcode) { view set CollSubPage.QrList }
        }
        space { ::exists{ !atBottom && weAreOwner() } }

        expanding - stack {
            ::exists { weAreOwner() }
            centered - actionButtonNoText(PIcon.guests) { view set CollSubPage.Guests }
        }
        space { exists = !atBottom }

        collectionActions(
            collectionId,
            if (atBottom) PopoverPreferredDirection.aboveLeft else PopoverPreferredDirection.belowLeft,
            setView = { view set it }
        )
    }
    bar - row {
        spacing = if (atBottom) 0.75.rem else 0.rem
        ::exists { selectedImages() != null }
        imageSelectedCounter(selectedImages)
        space {} in weight(1f)

        actionButtonNoText(
            displayIcon = PIcon.download,
            enabled = shared { selectedImages.await()?.isNotEmpty() == true }
        ) {
            val message = when (Platform.current) {
                Platform.Web -> "Selected items will be downloaded to your device as a zip file."
                Platform.iOS -> "Selected items will be saved to your Camera Roll."
                else -> "Selected items will be saved to your device."
            }
            showConfirmDialog("Confirm Download", message, "Download") {
                collectionDownload(collectionId(), selectedImages()?.map(::UploadId))
                selectedImages set null
            }
        }
        space { exists = Platform.current != Platform.Web }
        actionButtonNoText(
            displayIcon = PIcon.upload,
            exists = Constant(Platform.current != Platform.Web),
            enabled = shared { selectedImages.await()?.isNotEmpty() == true }
        ) {
            collectionShareSeparate(collectionId(), selectedImages()?.map(::UploadId)?.toSet())
            selectedImages set null
        }
        space {}
        actionButtonNoText(
            displayIcon = PIcon.trash,
            exists = shared { weAreOwner() || session()?.isVerifiedAccount() == false },
            enabled = shared { selectedImages.await()?.isNotEmpty() == true }
        ) {
            showConfirmDialog(
                title = "Confirm Delete",
                content = "Selected items will be deleted from the collection.",
                confirmLabel = "Delete",
                onConfirm = {
                    val selected = selectedImages() ?: emptyList()
                    val images = imagePaging().all().filter { it.uploadId.raw in selected }
                    selectedImages set null
                    deleteImages(images)
                }
            )
        }
    }
    atTop - separatorTheme - separator() {
        ::exists { isSmallScreen() }
    }
}

