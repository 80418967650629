package com.picme

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.models.Icon.StrokeLineCap
import com.lightningkite.kiteui.models.Icon.StrokePathData
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.actuals.animatePulsating
import com.picme.components.*
import com.picme.sdk2.*
import com.picme.sdk2.caching.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.MimeType
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.sdk2.generated.UserId
import com.picme.sdk2.generated.authentication.UserData
import com.picme.sdk2.generated.collection2.*
import com.picme.views.*
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable

val Icon.Companion.dropdown
    get() = Icon(
        width = 1.8.rem,
        height = 1.8.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf("M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z")
//    strokePathDatas = listOf(StrokePathData(strokeWidth = 2.dp, path = "M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"))
    )

fun String.pluralize(count: Int, pluralForm: String = "${this}s"): String = if (count == 1) this else pluralForm


fun formatIsoDate(isoDateString: String): String {
    val instant = Instant.parse(isoDateString)
    val dateTime = instant.toLocalDateTime(TimeZone.currentSystemDefault())
    return "${dateTime.month.name.lowercase().capitalize()} ${dateTime.dayOfMonth}, ${dateTime.year}"
}


fun String.validDownloadableName(): String {
    return this.split("/").last().replace(" ", "_").replace("~", "_").replace("+", "_")
}

suspend fun Session.isVerifiedAccount(): Boolean {
    return this.authenticatedUser().emailVerified || this.authenticatedUser().phoneNumberVerified
}

fun ownsCollection(collection: ListedCollection?): Boolean {
    if (collection == null) return false
    val userId = session.value?.authenticatedUser?.state?.raw?.userId ?: UserId("")
    return ownsCollection(collection, userId)
}

fun ownsCollection(collection: ListedCollection?, userId: UserId): Boolean {
    if (collection == null) return false
    return userId.raw == collection.collection.creatorUserId.raw
}

suspend fun ownsPCollection(collection: PCollection?): Boolean {
    if (collection == null) return false
    val userId = session()?.authenticatedUser()?.userId ?: return false
    return ownsCollection(session()?.collection2?.getCollectionLive(collection.collectionId)?.await(), userId)
}

suspend fun PCollection.images(): Paged<ListedUpload> {
    return session()!!.collection2.listUploadsLive(this.collectionId)
}

suspend fun PCollection.trashedImages(): Paged<ListedUpload> {
    return session()!!.collection2.listDeletedUploadsLive(this.collectionId)
}


val imgMap = mutableMapOf<String, Boolean>()

suspend fun String.imageIfExists(): ImageRemote? {
    if (this.isEmpty()) return null
    return imgMap[this]?.let { if (it) ImageRemote(this) else null } ?: (try {
        if (fetch(this).ok) ImageRemote(this)
        else null
    } catch (e: Exception) {
        null
    }).also { imgMap[this] = it != null }
}


fun List<ListedUpload>.toRecyclableInfo(): List<RecyclableInfo> = map {
    RecyclableInfo(id = it.uploadId.raw, thumbnail = uploadToLocal[it.uploadId] ?: it.thumbnailUrl.let(::ImageRemote))
}

suspend fun getDetailsUri(id: UploadId): String {
    val details = shared {
        session.awaitNotNull().collection2.getUploadLive(
            collectionId = currentCollection()?.collectionId!!,
            uploadId = id
        )()
    }
    return details().getDetailsUri

}

fun List<ListedUpload>.toImageDisplayInfo(): List<ImageDisplayInfo> = map {
    val details = shared {
        session.awaitNotNull().collection2.getUploadLive(
            collectionId = currentCollection()?.collectionId!!,
            uploadId = it.uploadId
        )()
    }
    ImageDisplayInfo(
        id = it.uploadId.raw,
        thumbnail = uploadToLocal[it.uploadId] ?: it.thumbnailUrl.let(::ImageRemote),
        originalImage = shared {
            if (!it.mimeType.raw.contains("video")) details().getDetailsUri.let(::ImageRemote) else null
        },
        originalVideo = shared {
            if (it.mimeType.raw.contains("video")) details().getDetailsUri.let(::VideoRemote) else null
        }
    )
}

val isSmallScreen = shared {
    if (Platform.current == Platform.iOS || Platform.current == Platform.Android) true
    else {
        AppState.windowInfo().width < 1020.dp
    }
}

fun ViewWriter.ifElse(
    condition: suspend () -> Boolean,
    ifTrue: ViewWriter.() -> Unit,
    ifFalse: ViewWriter.() -> Unit
) {
    stack {
        spacing = 0.dp
        ::exists { condition() }
        ifTrue()
    }
    stack {
        spacing = 0.dp
        ::exists { !condition() }
        ifFalse()
    }
}

suspend fun ViewWriter.uploadExternalFiles(
    collId: CollectionId,
    onFinish: (() -> Unit) = {}
) {
    val chosen = ExternalServices.requestFiles(listOf("image/*", "video/*"))
    if (chosen.isEmpty()) return
    uploadAllExternalFiles(collId, onFinish, chosen)
}

suspend fun ViewWriter.uploadExternalFilesFirstTime(
    collId: CollectionId,
    onFinish: (() -> Unit) = {}
) {
    val chosen = ExternalServices.requestFiles(listOf("image/*", "video/*"))
    if (chosen.isEmpty()) return

    val name = (session()?.authenticatedUser()?.name ?: "").ifBlank { askForName().await() }
    if (name.isBlank()) return

    uploadAllExternalFiles(
        collId = collId,
        onFinish = onFinish,
        chosen = chosen,
        waitFor = async {
            if (session.value?.isVerifiedAccount() != true) {
                createAccountIfNewUser(name)
            }
            acceptQrCode()
        }
    )
}

private suspend fun ViewWriter.uploadAllExternalFiles(
    collId: CollectionId,
    onFinish: (() -> Unit) = {},
    chosen: List<FileReference>,
    waitFor: Async<Unit>? = null
) {

    val useBackgroundUpload = false //Platform.current == Platform.iOS
    if (useBackgroundUpload) {
        waitFor?.await()
        for (file in chosen) {
            val uploadInfo = session.awaitNotNull().collection2.createUpload(
                collectionId = collId,
                body = CreateUploadBody(
                    filename = file.fileName(),
                    contentType = MimeType(file.mimeType()),
                ),
                allowDuplicates = true
            )
//            uploadManager.startBackgroundUploadRequest(uploadInfo)
//            session.awaitNotNull().collection(collId).backgroundUpload(file)

        }
    } else {
        val map = HashMap<FileReference, Async<ContinueUpload>>()
        fun prepare(file: FileReference) = map.getOrPut(file) {
            asyncGlobal {
                session.awaitNotNull().collection2.createUpload(
                    collectionId = collId,
                    data = RequestBodyFile(file),
                    hashCode = "${file.fileName()} ${file.bytes()} ${file.mimeType()}".hashCode().toString()
                )
            }
        }

        var successes = 0
        openProgressModal(
            title = "Uploading",
            execute = {
                itemCount = chosen.size
                image = chosen[0].let(::ImageLocal)
                waitFor?.await()
                println("Starting to upload")
                chosen.indices.forEach { index ->
                    image = chosen[index].let(::ImageLocal)
                    currentIndex = index
                    val c = prepare(chosen[index])
                    chosen.getOrNull(index + 1)?.let(::prepare)
                    chosen.getOrNull(index + 2)?.let(::prepare)
                    chosen.getOrNull(index + 3)?.let(::prepare)
                    try {
                        c.await().invoke { individualItemProgress = it.toFloat() }
                        individualItemProgress = 1f
                        successes++
                    } catch (e: Exception) {
                        e.report("image upload")
                    }
                }
            },
            onComplete = {
                if (successes > 0) {
                    showToast(
                        "Successfully uploaded $successes ${"file".pluralize(successes)}.",
                        "Files will be available soon."
                    )
                    onFinish()
                }
            }
        )
    }
}

suspend fun ViewWriter.restoreImages(images: List<ListedUpload>, all: Boolean = false) {
    val collection = currentCollection() ?: return
    var successes = 0
    openProgressModal("Restoring", execute = {
        itemCount = images.size
        for ((index, it) in images.withIndex()) {
            currentIndex = index
            image = it.thumbnailUrl.let(::ImageRemote)
            try {
                session.awaitNotNull().collection2.restoreDeletedUploads(
                    collectionId = collection.collectionId,
                    uploadIds = listOf(it.uploadId)
                )
                successes++
            } catch (e: Exception) {
                e.report()
            }
        }
    }, onComplete = {
        if (successes > 0) {
            if (all) showToast("Successfully restored all items")
            else showToast("Successfully restored ${"image".pluralize(images.size)}")
        }
    })
}

suspend fun ViewWriter.deleteImages(images: List<ListedUpload>, all: Boolean = false) {
    val collection = currentCollection() ?: return
    var successes = 0
    openProgressModal(
        title = "Deleting",
        execute = {
            itemCount = images.size
            images.forEachIndexed { index, it ->
                currentIndex = index
                try {
                    image = it.thumbnailUrl.let(::ImageRemote)
                    session.awaitNotNull().collection2.deleteUpload(
                        collectionId = collection.collectionId,
                        uploadId = it.uploadId
                    )
                    individualItemProgress = 1f
                    successes++

                } catch (e: Exception) {

                }
            }
        },
        onComplete = {
            if (successes > 0) {
                if (all) showToast("Successfully deleted all items")
                else showToast("Successfully moved ${"image".pluralize(images.size)} to trash")
            }

        }
    )
}

val successZipLink = Property<String?>(null)
expect suspend fun ViewWriter.collectionDownload(collectionId: CollectionId, uploadIds: List<UploadId>? = null)

suspend fun ViewWriter.collectionShareSeparate(collectionId: CollectionId, uploadIds: Set<UploadId>? = null) {
    val images = session.awaitNotNull().collection2.listUploadsLive(collectionId).all()
        .filter { if (uploadIds != null) it.uploadId in uploadIds else true }

    openProgressModal(
        title = "Preparing to share files",
        execute = {
            itemCount = images.size
            ExternalServices.share(images.mapIndexed { index, it ->
                currentIndex = index
                image = it.thumbnailUrl.let(::ImageRemote)
                val filename = it.uploadId.raw.validDownloadableName()
                val uploadDetails = session.awaitNotNull().collection2.getUploadLive(
                    collectionId = collectionId,
                    uploadId = it.uploadId
                )
                val downloadURL = uploadDetails().upload.location
                filename to fetch(url = downloadURL, onDownloadProgress = { downloaded, expected ->
                    individualItemProgress = downloaded.toDouble().div(expected).toFloat()
                }).blob()
            })
        },
        onComplete = {}
    )
}

suspend fun ViewWriter.navigateToCollOrLanding(
    notThisCollection: CollectionId? = null
) {
    val coll = currentCollection() ?: session.awaitNotNull().collection2.listCollectionsLive().all().firstOrNull {
        it.collection.collectionId != notThisCollection
    }?.collection
    val navTo = coll?.let {
        CollectionDetail(it.collectionId.raw.toSafeEncoded())
    } ?: CollectionLanding

    dialogScreenNavigator.clear()
    mainScreenNavigator.replace(navTo)
}

suspend fun ViewWriter.acceptQrCode() {
    val invite = acceptingInvite() ?: return
    if (invite.alreadyAccepted) return postAccept()
    val requireFullAuth = (invite.qrCode?.clientInfo()?.type != InviteType.RequestUploads ||
            (invite.legacy?.sharingAuthCode != null))

    val session = session() ?: return
    if (requireFullAuth && !session.isVerifiedAccount()) return postAccept()

    if (invite.qrCode != null) {
        try {
            println("Start accepting")
            session.collection2.activateInviteCode(invite.qrCode.inviteCodeId)
            println("Done accepting.")
        } catch (e: Exception) {
            e.printStackTrace2()
        }

        if (invite.collection != null) {
            val secondaryText = if (invite.qrCode.clientInfo()?.type == InviteType.ShareColl) {
                "You can now view the collection!"
            } else null
            showToast("Invitation accepted", secondaryText)
        }
        markAsAccepted()
    }

    if (invite.legacy != null) {
        val collectionId = invite.collection ?: return
        if (invite.legacy.sharingAuthCode == null) {
            session.collection2.requestConnect(collectionId)
        } else {
            session.collection2.sharingConnect(invite.legacy.sharingAuthCode, collectionId.raw)
        }
        markAsAccepted()
    }

    postAccept()
}

suspend fun markAsAccepted() {
    acceptingInvite set acceptingInvite()?.copy(alreadyAccepted = true)
    if (session() != null) {
        forceRefresh()
    }
}

suspend fun ViewWriter.postAccept() {
    val fullAuth = session()?.isVerifiedAccount() ?: false
    val invite = acceptingInvite() ?: return

    if (fullAuth) {
        if (invite.collection != null) {
            dialogScreenNavigator.clear()
            mainScreenNavigator.reset(CollectionDetail(invite.collection.raw.toSafeEncoded()))
        } else {
            navigateToCollOrLanding()
        }
        acceptingInvite set null
        return
    }

    val type = invite.qrCode?.clientInfo()?.type


    val nextScreen = when (type) {
        InviteType.Referral -> LoginOrSignUp()
        InviteType.ShareColl -> LoginOrSignUp()
        null -> LoginOrSignUp()
        else -> null
    }

    if (nextScreen != null) {
        mainScreenNavigator.reset(nextScreen)
    }
}


suspend fun createAccountIfNewUser(name: String = "") {
    if (sessionRefreshToken() == null) {
        emailOrPhone set ""
        val createPartialRes = unauthApi().authenticationHandler.authenticateAsGuest(
            setTosRead = true, userName = name,
            mailFromAddress = "",
            accessToken = { null }
        )
        val auth = createPartialRes.authenticated
        session set Session(
            unauthApi(), auth, UserData(
                userId = createPartialRes.guestId,
                userGlobalId = createPartialRes.guestGlobalId
            )
        )
    }
}

fun ViewWriter.fullScreenLoading(exists: Readable<Boolean>) {
    centered - col {
        ::exists { exists() }
        centered - picmeIconDisplay(64.dp)
        ThemeDerivation { it.copy(foreground = Color.picmePurple).withoutBack }.onNext - HeaderSemantic.onNext - text {
            align = Align.Center
            content = "capture experiences"
        }
        animatePulsating()
    }
}

fun showToast(primary: String, secondary: String? = null, duration: Long = 5000) {
    launchGlobal {
        val newToast = CurrentToast(
            messagePrimary = primary,
            messageSecondary = secondary,
        )
        currentToast set newToast
        delay(duration)
        currentToast set null
    }
}


val emailPattern = Regex("^[a-zA-Z0-9.!#\$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*\$")
fun String.isValidEmailOrPhone(): Boolean {
    return this.matches(emailPattern) ||
            this.replace(Regex("[^0-9]"), "").length == 10
}

fun String.isPhone(): Boolean {
    return this.replace(Regex("[^0-9]"), "").length == 10
}

suspend fun removeCaches() {
    GeneratedQrCache.invalidateAll()
    InviteCache.invalidateAll()
    val api = session()?.collection2
    if (api is CollectionHandler2ApiCacheableLive2) {
        api.removeEntireCache()
    }
}


suspend fun forceRefresh() {
    val api = session()?.collection2
    if (api is CollectionHandler2ApiCacheableLive2) {
        api.forceRefresh()
    }
}

suspend fun checkRefreshCollImages() {
    val collectionId = currentCollection()?.collectionId ?: return
    val api = session()?.collection2
    if (api is CollectionHandler2ApiCacheableLive2) {
        api.checkRefreshCollImages(collectionId)
    }
}

val logoutTrigger = Property(false)

suspend fun logout() {
    logoutTrigger set true
}

@Serializable
enum class GridSize { Large, Small }

suspend fun toggleGridSize() {
    val userId = session()?.authenticatedUser()?.userId ?: return
    gridSize set (gridSize().plus(
        userId to when (getGridSize()) {
            GridSize.Large -> GridSize.Small
            GridSize.Small -> GridSize.Large
        }
    ))
}

suspend fun getGridSize(): GridSize {
    val userId = session()?.authenticatedUser()?.userId ?: return GridSize.Small
    return gridSize()[userId] ?: GridSize.Small
}

val gridSize = PersistentProperty<Map<UserId, GridSize>>("picme-grid-size", mapOf())

val session: Property<Session?> = Property(null)

val sessionRefreshToken = PersistentProperty<String?>("picme-refresh-token", null)
val currentCollection = PersistentProperty<PCollection?>("current-collection", null)

val emailOrPhone = PersistentProperty("picme-user-email", "")
