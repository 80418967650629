package com.picme.views

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.awaitNotNull
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.session
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionUserData

fun String.anonymousIfEmpty(): String = this.ifEmpty { "Anonymous" }

fun String.userIconColor(): Color {
    val notEmpty = this.anonymousIfEmpty()
    val hash = notEmpty.hashCode()
    val r = (hash shr 16) and 0xFF
    val g = (hash shr 8) and 0xFF
    val b = hash and 0xFF
    return Color.fromHexString("#${r.toString(16)}${g.toString(16)}${b.toString(16)}")
}

class Guests(val collectionId: CollectionId,val onBack: suspend ()-> Unit) : PicmeDialog() {
    override val title = Constant("Guests")
    override val leftAlignIfLarge = true
    override fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = Constant("Guests"),
        onBack = onBack,
    )

    override fun ViewWriter.content() {
        expanding - col {
            separatorTheme - separator {}
            val sharees = Property<List<CollectionUserData>>(emptyList())
            val loading = Property(false)

            launch {
                loading set true
                val coll = session.awaitNotNull().collection2.getCollectionLive(collectionId)

                session.awaitNotNull().collection2.listUsers(collectionId).let {
                    sharees set it.users.filterNot { it.userId == coll().collection.creatorUserId }
                        .sortedByDescending { it.participationRights.value }
                }
                loading set false
            }

            padded - col {
                ::exists { loading() }
                space(); space();space()
                centered - activityIndicator()
            }
            centered - padded - text {
                content = "There are no guests."
                ::exists { !loading() && sharees().isEmpty() }
            }

            scrolls - expanding - col {
                ::exists { !loading() && sharees().isNotEmpty() }
                forEach(sharees) { guest ->
                    col {
                        stack {
                            atStart - row {
                                sizeConstraints(
                                    width = 3.rem,
                                    height = 3.rem
                                ) - centered - buttonTheme - ThemeDerivation {
                                    it.copy(
                                        background = guest.name.userIconColor(),
                                        foreground = Color.white
                                    ).withBack
                                }.onNext - padded - stack {
                                    centered - h6 { content = guest.name.anonymousIfEmpty().take(1) }
                                }
                                centered - col {
                                    spacing = 0.rem
                                    h4(guest.name.anonymousIfEmpty())
                                    fadedForeground.onNext - text {
                                        ::content { guest.participationRights.rightsOnCollection() }
                                    }
                                }
                            }

                            atEnd - iconButtonTheme - menuButton {
                                requireClick = true
                                centered - icon { source = PIcon.more }
                                preferredDirection = PopoverPreferredDirection.belowLeft
                                opensMenu {
                                    popoverWrapper {
                                        popoverItem {
                                            icon.source = PIcon.unlink
                                            text.content = "Disconnect"
                                            button.onClick {
                                                closePopovers()
                                                showConfirmDialog(
                                                    title = "Disconnect Collection",
                                                    content = "Are you sure you want to disconnect ${if (guest.name.isBlank()) guest.name else "this guest"} from this collection?",
                                                    warning = "This action cannot be undone.",
                                                    confirmLabel = "Disconnect",
                                                    onConfirm = {
                                                        session.awaitNotNull().collection2.revokeRights(
                                                            collectionId,
                                                            guest.userId
                                                        )
                                                        sharees set (sharees().filter { it.userId != guest.userId })
                                                    }
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        sizeConstraints(height = 0.25.rem) - space()
                    }
                }
            }
        }
    }
}
