package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.dynamicTheme
import com.lightningkite.kiteui.views.reactiveScope
import com.picme.Resources
import com.picme.currentCollection
import com.picme.sdk2.generated.AdSize
import com.picme.session

const val adUploadId = "ad"

data class ImageDisplayInfo(
    val id: String,
    val thumbnail: ImageSource,
    val originalImage: Readable<ImageSource?> = Constant(null),
    val originalVideo: Readable<VideoSource?> = Constant(null),
)

class ImageViewPager(
    val images: Readable<List<ImageDisplayInfo>>,
    val imageViewPagerOverlay: Stack.(
        withAds: Readable<MutableList<ImageDisplayInfo>>,
        imageId: Readable<RecyclableInfo>
    ) -> Unit
) : Screen {
    val imageId = Property(RecyclableInfo("", ImageRemote("")))

    private val imagesWithAds = shared {
        images().fold(mutableListOf<ImageDisplayInfo>()) { acc, curr ->
            acc.add(curr)
            if (acc.size % 5 == 0) {
                acc.add(
                    ImageDisplayInfo(
                        id = adUploadId,
                        thumbnail = Resources.picMePaigeAdVertical,
                        originalImage = shared {
                            session.awaitNotNull().ad.adServe(
                                collectionId = currentCollection()!!.collectionId.raw,
                                adSize = AdSize.Medium,
                                contextId = null,
                                unitTest = false
                            )
                        }
                    )
                )
            }
            acc
        }
    }

    override fun ViewWriter.render() = unpadded - stack {
        dynamicTheme {
            ThemeDerivation {
                it.copy(
                    id = "vp-button",
//                    background = Color.black,
                    cornerRadii = CornerRadii.Constant(0.dp),
                    button = {
                        it.copy(
                            background = Color.black.applyAlpha(0.5f),
                            foreground = Color.white,
                            iconOverride = Color.white,
                            cornerRadii = CornerRadii.RatioOfSize(2f),
                            spacing = spacing * 0.75
                        )
                    }
                ).withBack
            }
        }

        unpadded - viewPager {

            children(imagesWithAds) { currImage ->
                stack {
                    spacing = 0.25.rem
                    image {
                        ::exists { currImage().originalVideo() == null }
                        reactiveScope {

                            source = currImage().thumbnail
                            currImage().originalImage()?.let { source = it }
                        }
                        scaleType = ImageScaleType.Fit
                    }
                    video {
                        ::exists { currImage().originalVideo() != null }
                        reactiveScope {
                            val s = currImage().originalVideo()
                            source = s
                            if (s != null) {
                                playing set true
                            }
                        }
                        scaleType = ImageScaleType.Fit
                    }
                }
            }
            index bind shared {
                imagesWithAds().indexOfFirst { it.id == imageId().id }
            }.withWrite {
                imageId.value = imagesWithAds()[it].let {
                    RecyclableInfo(it.id, it.thumbnail)
                }
            }
        }
        imageViewPagerOverlay(imagesWithAds, imageId)

    }
}

